import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  isCurrentOpeningVisible: boolean = true;

  constructor(private router: Router) {
    router.events
    .subscribe(event =>
     {
      if(event instanceof NavigationEnd){
      this.isCurrentOpeningVisible = event.url != '/current-opening'
        console.log(event);
      }
     });
}



  ngOnInit(): void {}
}
