<section class="topimg about-header">
    <div class="container">
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-sm-12 col-12 d-flex align-items-center justify-content-center" data-aos="fade-up" data-aos-duration="2000">
                <div>
                    <h3 class="title-text">IT Infra And Cloud</h3>
                    <p class="title-text1">
                        Infrastructure is a very basic requirement to build anything. ComUnus expertise lies in IT Infrastructure services like Operating System and Middleware Technologies.
                    </p>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-sm-12 col-12 d-flex align-items-center justify-content-center" data-aos="fade-up" data-aos-duration="2000">
                <div class="header-img"></div>
            </div>
        </div>
    </div>
</section>

<section class="custom-app">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <img src="assets/img/it-infra1.png" />
            </div>
            <div class="col-md-6">
                <div class="title-text1">
                    <p>
                        Although we can handle ANYTHING and EVERYTHING in IT infrastructure, but at ComUnus Technologies Pvt. Ltd. our prime focus is on Operating Systems Services and Middleware Services.
                    </p>
                    <p>
                        Infrastructure is a very basic requirement to build anything - be it a Nation or a Simple Software Program. ComUnus expertise lies in IT Infrastructure services like Operating System and Middleware Technologies
                    </p>
                    <h3>Operating System services:</h3>
                    <p>
                        ComUnus provides expert OS services in UNIX as well as Windows environments.
                    </p>
                    <ul>
                        <li>IBM AIX</li>
                        <li>Oracle Solaris</li>
                        <li>Microsoft Windows</li>
                        <li>Linux (RHEL/CentOS)</li>
                    </ul>
                </div>
            </div>
            <div class="dig-grid">
                <div class="col-md-6">
                    <div class="title-text1">
                        <h3>Middleware Services</h3>
                        <p>
                            Implementing and managing middleware can be rigorous and complex, leaving your organization susceptible to bottlenecks and costly errors. ComUnus middleware services will help technology to drive your business and increase profitability.
                        </p>
                        <p>
                            We provide end-to-end Middleware services. This includes support operations, administrative services, performance and capacity management, security Management etc.
                        </p>
                        <p>
                            Whether you are using legacy systems, cloud or a combination of the two, ComUnus can help. And by implementing a robust IT service management framework, you can reduce costs, improve productivity and automate key tasks. Highly documented best practices
                            based deployments. Following are the Middleware products that we support -
                        </p>

                        <ul>
                            <li>
                                Application Servers - IBM WebSphere, Oracle WebLogic, Pramati, Apache Tomcat, Redhat JBoss and Microsoft IIS.
                            </li>
                            <li>
                                Web Servers - IBM HTTP Server, Oracle HTTP Server, Apache, Microsoft IIS.
                            </li>
                            <li>Messaging Systems - IBM WebSphere MQ and Message Broker .</li>
                            <li>Document Management Systems - IBM Content Manager.</li>
                            <li>BPM - IBM BPM</li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-6">
                    <img src="assets/img/it-infra2.png" />
                </div>
            </div>
        </div>
    </div>
</section>

<!-- OUR INDUSTRY SECTION -->
<app-our-services></app-our-services>
