import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http:HttpClient) { }

  contactApi(params): Observable<any>{
    return this.http.post(environment.API_ENDPOINT + 'save-contact-details', params);
  }

  careerApi(data): Observable<any>{
    return this.http.post(environment.API_ENDPOINT + 'save-career-details', data);
  }

  jdApi(): Observable<any>{
    return this.http.get(environment.API_ENDPOINT + 'get-jd-details');
  }
}
