import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { BankingFinanceComponent } from './banking-finance/banking-finance.component';
import { ConsultingManagedServiceComponent } from './consulting-managed-service/consulting-managed-service.component';
import { ContactComponent } from './contact/contact.component';
import { CurrentOpeningComponent } from './current-opening/current-opening.component';
import { DigitalTransformationComponent } from './digital-transformation/digital-transformation.component';
import { EcommerceRetailComponent } from './ecommerce-retail/ecommerce-retail.component';
import { FintechComponent } from './fintech/fintech.component';
import { HomeComponent } from './home/home.component';
import { InsuranceComponent } from './insurance/insurance.component';
import { ItInfraClouldComponent } from './it-infra-clould/it-infra-clould.component';
import { MobilitySolutionsComponent } from './mobility-solutions/mobility-solutions.component';
import { QaTestingComponent } from './qa-testing/qa-testing.component';
import { SoftwareDevelopmentComponent } from './software-development/software-development.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'software-development', component: SoftwareDevelopmentComponent },
  { path: 'mobility-solutions', component: MobilitySolutionsComponent },
  { path: 'digital-transformation', component: DigitalTransformationComponent },
  { path: 'it-infra-cloud', component: ItInfraClouldComponent },
  {
    path: 'consulting-managed-service',
    component: ConsultingManagedServiceComponent,
  },
  { path: 'qa-testing', component: QaTestingComponent },
  { path: 'banking-finance', component: BankingFinanceComponent },
  { path: 'fintech', component: FintechComponent },
  { path: 'insurance', component: InsuranceComponent },

  { path: 'ecommerce-retail', component: EcommerceRetailComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'current-opening', component: CurrentOpeningComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
export const routingComponents = [
  HomeComponent,
  AboutComponent,
  SoftwareDevelopmentComponent,
  MobilitySolutionsComponent,
  DigitalTransformationComponent,
  ItInfraClouldComponent,
  ConsultingManagedServiceComponent,
  QaTestingComponent,
  BankingFinanceComponent,
  FintechComponent,
  InsuranceComponent,
  EcommerceRetailComponent,
  ContactComponent,
  CurrentOpeningComponent,
];
