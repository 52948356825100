<section class="topimg about-header">
    <div class="container">
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-sm-12 col-12 d-flex align-items-center justify-content-center" data-aos="fade-up" data-aos-duration="2000">
                <div>
                    <h3 class="title-text">
                        Consulting & Managed Services
                    </h3>
                    <p class="title-text1">
                        We, at ComUnus, provide specialized Application Maintenance & Support (AMS) services to help you leverage your investments in your applications.
                    </p>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-sm-12 col-12 d-flex align-items-center justify-content-center" data-aos="fade-up" data-aos-duration="2000">
                <div class="header-img"></div>
            </div>
        </div>
    </div>
</section>

<section class="custom-app">
    <div class="container">
        <div class="row">
          <div class="col-md-6 pt-5">
            <img src="assets/img/visible-consult.png" class="img-fluid"/>
        </div>
        <div class="col-md-6 pt-5">
            <div class="title-text1">
              <h5>Leverage Global Expertise to Support Your IT Environment</h5>
              <p>The Managed Services delivery model for Information Technology services has become more and more popular in recent years. It offers a measurable approach to achieving services at a lower cost while minimizing risk and
                  improving quality. This model is ideal when multiple players participate in a single IT support activity. We offers a variety of industry-leading services using this model.</p>
              <p> ComUnus Technologies Pvt. Ltd. Create competitive advantage for clients by delivering cost-effective, predictable and reliable managed services that effectively balance costs, boost performance and drive efficiency.
                  Our highly skilled team of experts provide the highest level of quality and scalable expertise today’s businesses need to maintain complex technology environments at affordable rates.</p>
            </div>
        </div>
        <div class="dig-grid">
            <div class="col-md-6 pt-5">
                <div class="title-text1">
                  <h5 class="pt-3">Flexible Service Portfolio</h5>
                  <p>ComUnus offerings are derived from a standard set of services focused on delivering a well-defined, repeatable, predictable service at a stable level over an extended period of time. Scope-appropriate service-level
                      commitments are described in a Service Level Agreement (SLA) and tailored to your requirements.</p>
                  <p>ComUnus can leverage our global team for cost effectiveness and extended coverage by seamlessly complimenting your team, fostering innovation, and adding business value to both IT and your business bottom line. Our
                      service portfolio is based on the IT Infrastructure Library® (ITIL®) framework with the following focused offerings:</p>
                  <p>ComUnus offers both packaged focus offerings as well as customizable managed service offerings tailored to your needs.</p>
                  <h5> Managed Services Focus Areas</h5>
                                    <ul class="list">
                                        <li>Operations Support Services</li>
                                        <li>Managed Application Services</li>
                                        <li>Managed Testing Services</li>
                                        <li>Managed Security Administration</li>
                                    </ul>
                </div>
            </div>
            <div class="col-md-6 pt-5">
                <img src="assets/img/portfolio.png" class="img-fluid" />
            </div>
            </div>
            <div class="dig-grid">
              <div class="col-md-6 pt-5">
                <img src="assets/img/app-mant.png" class="img-fluid" />
              </div>
              <div class="col-md-6 pt-5">
                <div class="title-text1">
                <h5>Application Maintenance:</h5>
                <p>It is essential that your applications evolve with your business. Every year, organizations apportion a huge amount of their IT Budget to Application Maintenance as opposed to Application Development. We, at ComUnus,
                    provide specialized Application Maintenance & Support (AMS) services to help you leverage your investments in your applications. We recognize that as the application matures, maintenance entails more enhancements
                    than defect-fixing and structure our support teams accordingly.</p>
                <p>Besides routine activities such as monitoring, regular housekeeping, defect-fixing and enhancements, our Technical Support Desk will also deliver
                    value added services to you. Code review and performance tuning both at Application as well as Application Infrastructure Level will be conducted to ensure that your IT spend is optimally leveraged. Moreover, we
                    can provide Application Maintenance Services for third party applications as well.</p>
                 <p>Our time tested transition methodology ensures smooth transition from current support teams ensuring zero impact to business
                    during the transition. We will help you reduce the time you spend on the maintenance of your applications and enable you to focus on your strategic business initiatives.</p>
              </div>
            </div>
            </div>
        </div>
    </div>
</section>

<!-- OUR INDUSTRY SECTION -->
<app-our-services></app-our-services>
