import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root',
})
export class ApiservicesService {
  constructor(private http: HttpClient) {}

  contactUs(params: any): Observable<any> {
    return this.http.post(environment.API_ENDPOINT + 'contacts/save', params)
  }

  postCareer(params: any): Observable<any> {
    return this.http.post(environment.API_ENDPOINT + 'career/save', params)
  }
}
