<section class="topimg about-header">
  <div class="container">
      <div class="row">
          <div class="col-xl-6 col-lg-6 col-sm-12 col-12 d-flex align-items-center justify-content-center" data-aos="fade-up" data-aos-duration="2000">
              <div>
                  <h3 class="title-text">Insurance</h3>
                  <p class="title-text1">
                      Life insurance companies, today, depend on technology to streamline their business processes such as enrollments.
                  </p>
              </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-sm-12 col-12 d-flex align-items-center justify-content-center" data-aos="fade-up" data-aos-duration="2000">
              <div class="header-img"></div>
          </div>
      </div>
  </div>
</section>

<section class="custom-app">
  <div class="container">
      <div class="row">
          <div class="col-md-6 pt-3">
              <img src="assets/img/insurance-img.jpg"  class="img-fluid">
          </div>
          <div class="col-md-6">
              <div class="title-text1">
                  <p>
                      Life insurance companies, today, depend on technology to streamline their business processes such as enrollments, underwriting, claims and customer service. In addition to this, stringent regulatory norms pertaining to claims verification and payout disbursement further makes it necessary to harness the power of IT. Data analytics also plays an important role in the decision making process, often affecting the entire enterprise business strategy. Insurance companies need to rely on real-time information delivery and hence the dependency on technology further goes up.
                  </p>
                  <p>
                      ComUnus Technologies Pvt. Ltd. offers a range of services and solutions to cater to the needs of Life & General Insurance companies. The objective is to empower each business user in each department to see business information in the format best suited for their needs, increase operational efficiency and reduce costs.
                  </p>
              </div>
          </div>
      </div>
  </div>
</section>

<!-- OUR INDUSTRY SECTION -->
<app-our-services></app-our-services>
