<div class="content" role="main">
    <!----------- Home  ---------->
    <div id="home" class="slider-area">
        <div class="hero-slider">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 col-xs-12">
                        <div class="layer-1-4" data-aos="fade-down" data-aos-duration="2000">
                            <div class="text-left deliver">
                                <h4>Delivering Imaginations</h4>
                                <h3 [@valueChanged]="homeSliderObj.title" [innerHtml]="homeSliderObj.title"></h3>
                                <img src="assets/img/Decore.png" class="dec" />
                                <p class="text-left head-desc" [@valueChanged]="homeSliderObj.subtitle"
                                    [innerHtml]="homeSliderObj.subtitle"></p>
                               <button routerLink="{{homeSliderObj.link}}" class="text-left readmore1" [@valueChanged]="homeSliderObj.button"
                                    [innerHtml]="homeSliderObj.button"></button>
                            </div>

                            <!-- <div *ngIf="let homeSliders of homeSliders">
                                <button class="readmore1">{{homeSliders.button}}</button>
                            </div> -->
                        </div>
                    </div>

                    <div class="col-md-5 col-xs-12">
                        <div class="container">
                            <div class="rotate-container">
                                <!-- <img src="assets/img/outer-circle.png" class="rotate rotate-smaller" width="350" height="350" /> -->
                                <img src="assets/img/outer-circle.png" class="rotate" />
                                <img [@valueChanged]="homeSliderObj.img" [src]="homeSliderObj.img" width="250"
                                    height="250" class="rotate-mini-slider" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <section id="counter" class="p-0" data-aos="fade-right" data-aos-duration="5000">
        <div class="container">
            <div class="col-md-12">
                <mat-card class="counter-card">
                    <h2 class="text-center about-us-title">Our Achievements</h2>
                    <mat-card-subtitle class="text-center">Comunus Where Client and Team matter the
                        most</mat-card-subtitle>

                    <ul class="counter-list">
                        <div class="col-xs-6 inline-grid">
                            <li class="counter-item list-group-item text-center">
                                <h1 class="counter">{{happyClient}}+</h1>
                                <mat-card-subtitle>Happy Client</mat-card-subtitle>
                            </li>
                            <li class="counter-item list-group-item text-center">
                                <h1 class="counter">{{projectHours}} Lac+</h1>
                                <mat-card-subtitle>Project Hours</mat-card-subtitle>
                            </li>
                        </div>
                        <div class="col-xs-6 inline-grid">
                            <li class="counter-item list-group-item text-center">
                                <h1 class="counter">{{teamStrength}}+</h1>
                                <mat-card-subtitle>Team Strength</mat-card-subtitle>
                            </li>
                            <li class="counter-item list-group-item text-center">
                                <h1 class="counter">{{tech}}+</h1>
                                <mat-card-subtitle>Technology & Skills</mat-card-subtitle>
                            </li>
                        </div>
                    </ul>

                </mat-card>
            </div>

        </div>
    </section>

    <!--our expertise-->
    <section class="exp">
        <div class="section is-sm section-grey">
            <div class="container">
                <div class="row">
                    <div class="exp-grid">
                        <div class="col-lg-4 fixed">
                            <div class="img_sticky">
                                <h3 class="title-text">Our Expertise</h3>
                                <p class="title-text1">
                                    We are not different, but we lead by creating a difference
                                </p>
                                <p class="title-text1">
                                    Since 2016, We are offering solutions and services to achieve scalable, digital,
                                    future-ready operations. We are a vibrant team of experienced digital enthusiasts
                                    who provide out-of-the-box ideas and solutions for our clients.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="exp-box">
                                        <img src="assets/img/Group-87.png" />
                                        <h3 class="title-text">Software Development</h3>
                                        <p>
                                            Packaged software often comes with its limitations. ComUnus Technologies
                                            Pvt. Ltd. help their customer for custom development projects for specific
                                            requirements.
                                        </p>
                                        <button class="read-exp" class="d-flex align-items-center"
                                            routerLink="/software-development">
                                            Read More<mat-icon> arrow_right_alt </mat-icon>
                                        </button>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="exp-box">
                                        <img src="assets/img/smartphone.png" />
                                        <h3 class="title-text">Mobility Solutions</h3>
                                        <p>
                                            Mobile technologies today have untethered businesses from their locations,
                                            unlocked immense business value, and unleashed innovative solutions to help
                                            businesses grow.
                                        </p>
                                        <button class="read-exp" class="d-flex align-items-center"
                                            routerLink="/mobility-solutions">
                                            Read More<mat-icon> arrow_right_alt </mat-icon>
                                        </button>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="exp-box">
                                        <img src="assets/img/Group-144.png" />
                                        <h3 class="title-text">Digital Transformation</h3>
                                        <p>
                                            We have observed that industry has undergone a wide range of transitions
                                            from being resource- pivotal to budget- pivotal, and finally to become
                                            customer- pivotal.
                                        </p>
                                        <button class="read-exp" class="d-flex align-items-center"
                                            routerLink="/digital-transformation">
                                            Read More<mat-icon> arrow_right_alt </mat-icon>
                                        </button>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="exp-box">
                                        <img src="assets/img/Group-143.png" />
                                        <h3 class="title-text">IT Infra And Cloud</h3>
                                        <p>
                                            Infrastructure is a very basic requirement to build anything. ComUnus
                                            expertise lies in IT Infrastructure services like Operating System and
                                            Middleware Technologies.
                                        </p>
                                        <button class="read-exp" class="d-flex align-items-center"
                                            routerLink="/it-infra-cloud">
                                            Read More<mat-icon> arrow_right_alt </mat-icon>
                                        </button>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="exp-box">
                                        <img src="assets/img/icon-mobility.png" />
                                        <h3 class="title-text">Consulting & Managed Services</h3>
                                        <p>
                                            We, at ComUnus, provide specialized Application Maintenance &amp; Support
                                            (AMS) services to help you leverage your investments in your applications.
                                        </p>
                                        <button class="read-exp" class="d-flex align-items-center"
                                            routerLink="/consulting-managed-service">
                                            Read More<mat-icon> arrow_right_alt </mat-icon>
                                        </button>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="exp-box">
                                        <img src="assets/img/Group-102.png" />
                                        <h3 class="title-text">Quality Assurance</h3>
                                        <p>
                                            Quality Assurance is any systematic process of checking to see whether a
                                            product or service being developed is meeting specified requirements.
                                        </p>
                                        <button class="read-exp" class="d-flex align-items-center"
                                            routerLink="qa-testing">
                                            Read More<mat-icon> arrow_right_alt </mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!--mobile view-->
    <div class="container mob-container pt-3">
        <div class="our-ind-header text-center">
            <h3 class="title-text">Our Expertise</h3>
            <p class="our-ind-text m-0">
                Since 2016, We are offering solutions and services to achieve scalable, digital, future-ready
                operations. We are a vibrant team of experienced digital enthusiasts who provide out-of-the-box ideas
                and solutions for our clients.
            </p>
        </div>
        <div class="ml-5 mr-5 " >
            <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide>
                    <div class="col-md-4">
                        <div class="exp-box">
                            <img src="assets/img/Group-87.png" />
                            <h3 class="title-text">Software Development</h3>
                            <p>
                                Packaged software often comes with its limitations. ComUnus Technologies Pvt. Ltd. help
                                their customer for custom development projects for specific requirements.
                            </p>
                            <button class="read-exp" class="d-flex align-items-center">Read More<mat-icon>
                                    arrow_right_alt </mat-icon></button>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="col-md-4">
                        <div class="exp-box">
                            <img src="assets/img/smartphone.png" />
                            <h3 class="title-text">Mobility Solutions</h3>
                            <p>
                                Mobile technologies today have untethered businesses from their locations, unlocked
                                immense business value, and unleashed innovative solutions to help businesses grow.
                            </p>
                            <button class="read-exp" class="d-flex align-items-center">
                                Read More<mat-icon> arrow_right_alt </mat-icon>
                            </button>
                        </div>
                    </div>
                </ng-template>

                <ng-template carouselSlide>
                    <div class="col-md-4">
                        <div class="exp-box">
                            <img src="assets/img/Group-144.png" />
                            <h3 class="title-text">Digital Transformation</h3>
                            <p>
                                We have observed that industry has undergone a wide range of transitions from being
                                resource- pivotal to budget- pivotal, and finally to become customer- pivotal.
                            </p>
                            <button class="read-exp" class="d-flex align-items-center">
                                Read More<mat-icon> arrow_right_alt </mat-icon>
                            </button>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="col-md-4">
                        <div class="exp-box">
                            <img src="assets/img/Group-143.png" />
                            <h3 class="title-text">IT Infra And CLoud</h3>
                            <p>
                                Infrastructure is a very basic requirement to build anything. ComUnus expertise lies in
                                IT Infrastructure services like Operating System and Middleware Technologies.
                            </p>
                            <button class="read-exp" class="d-flex align-items-center">
                                Read More<mat-icon> arrow_right_alt </mat-icon>
                            </button>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="col-md-4">
                        <div class="exp-box">
                            <img src="assets/img/icon-mobility.png" />
                            <h3 class="title-text">Consulting Managed Services</h3>
                            <p>
                                We, at ComUnus, provide specialized Application Maintenance &amp; Support (AMS) services
                                to help you leverage your investments in your applications.
                            </p>
                            <button class="read-exp" class="d-flex align-items-center">
                                Read More<mat-icon> arrow_right_alt </mat-icon>
                            </button>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="col-md-4">
                        <div class="exp-box">
                            <img src="assets/img/Group-102.png" />
                            <h3 class="title-text">Quality Assurance</h3>
                            <p>
                                Quality Assurance is any systematic process of checking to see whether a product or
                                service being developed is meeting specified requirements.
                            </p>
                            <button class="read-exp" class="d-flex align-items-center">
                                Read More<mat-icon> arrow_right_alt </mat-icon>
                            </button>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>

    <!-- OUR INDUSTRY SECTION -->
    <div class="container our-ind-container pt-3">
        <div class="our-ind-header text-center">
            <h3 class="title-text text-white">Our Clientele</h3>
            <p class="our-ind-text m-0">
                We bring IT Solutions and Services with deep domain and industry experience to build unique perspectives
                and meet the challenging requirements of global clients from diverse industries like Banking & Finance,
                Insurance, Automobiles, Fintech, E-Commerce
            </p>
        </div>
        <div class="ml-5 mr-5">
            <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide>
                    <div class="mx-auto d-block">
                        <div class="our-ind-card-carousel">
                            <div class="our-ind-card-carousel-img">
                                <img src="assets/img/banking-fiance.png" />
                            </div>
                            <div class="our-ind-card-carousel-content">
                                <h2 class="our-ind-card-carousel-content-header m-0">
                                    Banking & Finance
                                </h2>
                                <p class="our-ind-card-carousel-content-text m-0 mt-2 mb-3">
                                    The growing demands of technology have necessitated banks to apportion a large
                                    portion of their budgets to IT.
                                </p>
                                <button class="read-exp" class="d-flex align-items-center"
                                    routerLink="/banking-finance">
                                    Read More<mat-icon> arrow_right_alt </mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="mx-auto d-block">
                        <div class="our-ind-card-carousel card-mt ">
                            <div class="our-ind-card-carousel-img">
                                <img src="assets/img/insurance.png" />
                            </div>
                            <div class="our-ind-card-carousel-content">
                                <h2 class="our-ind-card-carousel-content-header m-0">
                                    Insurance
                                </h2>
                                <p class="our-ind-card-carousel-content-text m-0 mt-2 mb-3">
                                    Life insurance companies, today, depend on technology to streamline their business
                                    processes such as enrollments.
                                </p>
                                <button class="read-exp" class="d-flex align-items-center" routerLink="/insurance">
                                    Read More<mat-icon> arrow_right_alt </mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-template>

                <ng-template carouselSlide>
                    <div class="mx-auto d-block">
                        <div class="our-ind-card-carousel card-mt">
                            <div class="our-ind-card-carousel-img">
                                <img src="assets/img/fintech.png" />
                            </div>
                            <div class="our-ind-card-carousel-content">
                                <h2 class="our-ind-card-carousel-content-header m-0">
                                    Fintech
                                </h2>
                                <p class="our-ind-card-carousel-content-text m-0 mt-2 mb-3">
                                    Financial institutions are also rethinking about their traditional methods and
                                    slowly adapting to the Fintech Solutions.
                                </p>

                                <button class="read-exp" class="d-flex align-items-center" routerLink="/fintech">
                                    Read More<mat-icon> arrow_right_alt </mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="mx-auto d-block">
                        <div class="our-ind-card-carousel card-mt">
                            <div class="our-ind-card-carousel-img">
                                <img src="assets/img/ECommerce.png" />
                            </div>
                            <div class="our-ind-card-carousel-content">
                                <h2 class="our-ind-card-carousel-content-header m-0">
                                    Retail & Ecommerce
                                </h2>
                                <p class="our-ind-card-carousel-content-text m-0 mt-2 mb-3">
                                    We bring IT Solutions and Services with deep domain and industry experience to build
                                    unique perspectives.
                                </p>

                                <button class="read-exp" class="d-flex align-items-center"
                                    routerLink="/ecommerce-retail">
                                    Read More<mat-icon> arrow_right_alt </mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>

    <!--Solution Page-->
    <section class="solution-grid">
        <div class="container">
            <div class="row">
                <div class="col-md-12 mt-3">
                    <h3 class="title-text" style="text-align: center">Our Products</h3>
                    <p class="title-text">
                        With the long term vision, we build solutions to decrease the gap between you and your
                        customers.
                    </p>
                </div>
                <div class="col-md-12">
                    <div class="hrms">
                        <div class="col-md-6">
                            <img src="assets/img/hrms1.png" alt="Vijay" class="img-fluid" />
                        </div>
                        <div class="col-md-6">
                            <div class="event-grid">
                                <h3 class="title-text">HRMS</h3>

                                <div class="icon-grid-pro">
                                    <ul class="title-li-pro">
                                        <li class="title-li">Where Employees Matter the Most!</li>
                                        <li class="d-flex align-items-center">
                                            <mat-icon class="mat-icon-pro">keyboard_arrow_right</mat-icon>Core HRMS
                                        </li>
                                        <li class="d-flex align-items-center">
                                            <mat-icon class="mat-icon-pro">keyboard_arrow_right</mat-icon>Payroll
                                            Management
                                        </li>
                                        <li class="d-flex align-items-center">
                                            <mat-icon class="mat-icon-pro">keyboard_arrow_right</mat-icon>Attendance
                                            Management
                                        </li>
                                        <li class="d-flex align-items-center">
                                            <mat-icon class="mat-icon-pro">keyboard_arrow_right</mat-icon>Leave
                                            Management
                                        </li>
                                        <li class="d-flex align-items-center">
                                            <mat-icon class="mat-icon-pro">keyboard_arrow_right</mat-icon>Employee Self
                                            Service
                                        </li>
                                    </ul>
                                </div>
                                <h5 style="font-size: 17px; padding-left: 1%; padding-bottom: 3%">
                                    Web/Android
                                </h5>
                                <div class="newsevents-item"></div>
                                <a class="btn" href="https://hrmsbuddy.com/" target="_blank">Read More</a>
                            </div>
                        </div>
                    </div>

                    <div class="onsite">
                        <div class="col-md-6">
                            <div class="event-grid">
                                <h3 class="title-text">Site in-charge</h3>
                                <div class="icon-grid-pro">
                                    <ul class="title-li-pro">
                                        <li class="title-li">
                                            Helps Construction Companies to Manage Better
                                        </li>
                                        <li class="d-flex align-items-center">
                                            <mat-icon class="mat-icon-pro">keyboard_arrow_right</mat-icon>Multiple Site
                                            Management
                                        </li>
                                        <li class="d-flex align-items-center">
                                            <mat-icon class="mat-icon-pro">keyboard_arrow_right</mat-icon>Chat/Share
                                            Images
                                        </li>
                                        <li class="d-flex align-items-center">
                                            <mat-icon class="mat-icon-pro">keyboard_arrow_right</mat-icon>Labour
                                            Attendance and Daily Cost Monitoring
                                        </li>
                                        <li class="d-flex align-items-center">
                                            <mat-icon class="mat-icon-pro">keyboard_arrow_right</mat-icon>
                                            Material Management
                                        </li>
                                        <li class="d-flex align-items-center">
                                            <mat-icon class="mat-icon-pro">keyboard_arrow_right</mat-icon>Petty-cash
                                            Management
                                        </li>
                                    </ul>
                                </div>
                                <h5 style="font-size: 17px; padding-left: 1%; padding-bottom: 3%">
                                    Web/Android
                                </h5>
                                <div class="newsevents-item"></div>
                                <a class="btn" href="http://siteincharge.com/" target="_blank">Read More</a>
                                <!-- <a class="btn" href="javascript:void(0)">Read More</a> -->
                            </div>
                        </div>
                        <div class="col-md-6">
                            <img src="assets/img/site.png" alt="Vijay" class="img-fluid" />
                        </div>
                    </div>

                    <div class="onsite-mob">
                        <div class="col-md-6">
                            <img src="assets/img/site.png" alt="Vijay" class="img-fluid" />
                        </div>
                        <div class="col-md-6">
                            <div class="event-grid">
                                <h3 class="title-text">-charge</h3>
                                <div class="icon-grid-pro">
                                    <ul class="title-li-pro">
                                        <li class="title-li">
                                            Helps Construction Companies to Manage Better
                                        </li>
                                        <li class="d-flex align-items-center">
                                            <mat-icon class="mat-icon-pro">keyboard_arrow_right</mat-icon>Multiple Site
                                            Management
                                        </li>
                                        <li class="d-flex align-items-center">
                                            <mat-icon class="mat-icon-pro">keyboard_arrow_right</mat-icon>Chat/Share
                                            Images
                                        </li>
                                        <li class="d-flex align-items-center">
                                            <mat-icon class="mat-icon-pro">keyboard_arrow_right</mat-icon>Labour
                                            Attendance and Daily Cost Monitoring
                                        </li>
                                        <li class="d-flex align-items-center">
                                            <mat-icon class="mat-icon-pro">keyboard_arrow_right</mat-icon>
                                            Material Management
                                        </li>
                                        <li class="d-flex align-items-center">
                                            <mat-icon class="mat-icon-pro">keyboard_arrow_right</mat-icon>Petty-cash
                                            Management
                                        </li>
                                    </ul>
                                </div>
                                <h5 style="font-size: 17px; padding-left: 1%; padding-bottom: 3%">
                                    Web/Android
                                </h5>
                                <div class="newsevents-item"></div>
                                <a class="btn" href="https://siteincharge.com/" target="_blank">Read More</a>
                                <!--<a class="btn" href="javascript:void(0)">Read More</a> -->
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--Solution Page-->

    <!-- ABOUT US SECTION -->
    <section class="pb-3 pt-3 about-us-container ">
        <div class="container ">
            <div class="row pl-3 pr-3">
                <div class="about-grid">
                    <div class="col-md-12 col-sm-12 col-xs-12 col-lg-6">
                        <div class="about-us-img-cards pt-5">
                            <div class="row">
                                <div class="col-md-6 col-6 about-us-img-card-single">
                                    <div class="about-us-img-div-first" [@valueChanged]="activeGalleryImages[0]"
                                        [ngStyle]="{
                          background:
                            'url(' + activeGalleryImages[0] + ') no-repeat 0 0 / cover'
                        }"></div>
                                </div>
                                <div class="col-md-6 col-6 about-us-img-card-single">
                                    <div class="about-us-img-div-second" [@valueChanged]="activeGalleryImages[1]"
                                        [ngStyle]="{
                          background:
                            'url(' + activeGalleryImages[1] + ') no-repeat 0 0 / cover'
                        }"></div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-6 about-us-img-card-single">
                                    <div class="about-us-img-div-third" [@valueChanged]="activeGalleryImages[2]"
                                        [ngStyle]="{
                          background:
                            'url(' + activeGalleryImages[2] + ') no-repeat 0 0 / cover'
                        }"></div>
                                </div>
                                <div class="col-md-6 col-6 about-us-img-card-single">
                                    <div class="about-us-img-div-fourth" [@valueChanged]="activeGalleryImages[3]"
                                        [ngStyle]="{
                          background:
                            'url(' + activeGalleryImages[3] + ') no-repeat 0 0 / cover'
                        }"></div>
                                </div>
                            </div>
                            <div class="about-us-circle">
                                <div
                                    class="about-us-inner-circle d-flex align-items-center justify-content-center text-center">
                                    <div>
                                        <h1 class="about-us-years-num">{{ years }}</h1>
                                        <p class="about-us-years-text">
                                            Years
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-xs-12 col-md-12 col-lg-6 d-flex align-items-center pl-1 pr-1 ">
                        <div class="about-txt">
                            <h2 class="about-us-title">About Us</h2>
                            <p class="about-us-text">
                                Since 2016, ComUnus Technologies Pvt Ltd is one of the fastest growing IT Services and
                                Consulting company based out of INDIA.
                            </p>
                            <p class="about-us-com-desc">
                                ComUnus word comes from community, which joins two Latin word
                                <span class="common-blue">Com</span>('together', 'with') and
                                <span class="common-orange">Unus</span>('One'). As the name suggest, we at ComUnus
                                believe in building relationship with our customers, team members, investors, and
                                partners.
                            </p>

                            <p class="about-us-text mt-3">
                                Technologies keep evolving, but relationship will always remain. Trust of our customers
                                strengthen us to work so passionately to build solutions, that can bring our customers
                                and their customers…come closer.
                            </p>
                            <p class="about-us-text mt-3">
                                We are delivering a whole lot for what lies upstream of innovation. Your Imaginations,
                                Our Solutions…
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-md-1 col-1"></div>
            </div>
        </div>
    </section>
    <!-- <section>
        <div class="container about-us-container pt-4">
            <div class="row pl-3 pr-3">
                <div class="about-grid">
                    <div class="col-6 col-md-6 col-sm-12 col-xs-12" style="padding-top: 4%">
                        <div class="about-us-img-cards">
                            <div class="row">
                                <div class="col-md-6 col-6 about-us-img-card-single">
                                    <div class="about-us-img-div-first" [@valueChanged]="activeGalleryImages[0]" [ngStyle]="{
                        background:
                          'url(' +
                          activeGalleryImages[0] +
                          ') no-repeat 0 0 / cover'
                      }"></div>
                                </div>
                                <div class="col-md-6 col-6 about-us-img-card-single">
                                    <div class="about-us-img-div-second" [@valueChanged]="activeGalleryImages[1]" [ngStyle]="{
                        background:
                          'url(' +
                          activeGalleryImages[1] +
                          ') no-repeat 0 0 / cover'
                      }"></div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-6 about-us-img-card-single">
                                    <div class="about-us-img-div-third" [@valueChanged]="activeGalleryImages[2]" [ngStyle]="{
                        background:
                          'url(' +
                          activeGalleryImages[2] +
                          ') no-repeat 0 0 / cover'
                      }"></div>
                                </div>
                                <div class="col-md-6 col-6 about-us-img-card-single">
                                    <div class="about-us-img-div-fourth" [@valueChanged]="activeGalleryImages[3]" [ngStyle]="{
                        background:
                          'url(' +
                          activeGalleryImages[3] +
                          ') no-repeat 0 0 / cover'
                      }"></div>
                                </div>
                            </div>
                            <div class="about-us-circle">
                                <div class="about-us-inner-circle d-flex align-items-center justify-content-center text-center">
                                    <div>
                                        <h1 class="about-us-years-num">{{ years }}</h1>
                                        <p class="about-us-years-text">
                                            Successful <br /> Years
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 pl-5 pr-5 col-sm-12 col-xs-12" style="padding-top: 4%">
                        <div>
                            <h1 class="about-us-title">About Us</h1>

                            <p class="about-us-text">
                                Since 2016, ComUnus Technologies Pvt Ltd is one of the fastest growing IT Services and Consulting company based out of INDIA.
                            </p>
                            <p class="about-us-com-desc">
                                ComUnus word comes from community, which joins two Latin word
                                <span class="common-blue">Com</span>('together', 'with') and
                                <span class="common-orange">Unus</span>('One'). As the name suggest, we at ComUnus believe in building relationship with our customers, team members, investors, and partners.
                            </p>

                            <p class="about-us-text mt-3">
                                Technologies keep evolving, but relationship will always remain. Trust of our customers strengthen us to work so passionately to build solutions, that can bring our customers and their customers…come closer.
                            </p>
                            <p class="about-us-text mt-3">
                                We are delivering a whole lot for what lies upstream of innovation. Your Imaginations, Our Solutions…
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->

    <!--News & Events Page-->
    <section class="news-title">
        <div class="container">
            <div class="row">
                <div class="col-lg-11 offest-lg-1 col-md-12">
                    <div class="para-grid">

                        <div class="col-md-4 col-xs-12">
                            <img src="assets/img/vijay.png" alt="Vijay" style="border-radius: 25px"
                                class="mx-auto d-block" />
                            <h3 class="news-grid">VIJAY K LOKHANDE</h3>
                        </div>
                        <div class="col-lg-8 col-md-12 col-xs-12">
                            <div class="event-grid">
                                <h3 class="title-text">News & Events</h3>
                                <div class="newsevents-item">
                                    <img src="assets/img/CIOReviewIndia-logo1.png" alt="cioreview" />
                                    <h3>
                                        ComUnus: Programmable Infrastructure Services Everywhere
                                    </h3>
                                    <p>
                                        Ideally, when it comes to corporate IT infrastructure, the business future and
                                        the IT infrastructure strategic needs should go hand in hand. Enterprises have
                                        to invest in a compatible and flexible IT infrastructure to support the change
                                        to drive digital
                                        business innovations. Based out of Mumbai, ComUnus Technologies expertise lays
                                        in IT Infrastructure services like Operating System, Middleware Technologies,
                                        Database, Storage, and Network.
                                    </p>
                                </div>
                                <a class="btn1 d-flex align-items-center" target="_blank"
                                    href="https://infrastructure-management-services.ciotechoutlook.com/vendor/2018/comunus_technologies">
                                    Read More<mat-icon> arrow_right_alt </mat-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--News & Events Page-->
</div>
