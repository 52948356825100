<main style="width: 100%">
    <section class="topimg about-header">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-sm-12 col-12 d-flex align-items-center justify-content-center"
                    data-aos="fade-up" data-aos-duration="2000">
                    <div>
                        <h3 class="title-text">
                            Software Development
                        </h3>
                        <p class="title-text1">
                            Packaged software often comes with its limitations. ComUnus Technologies Pvt. Ltd. help
                            their customer for custom development projects for specific requirements
                        </p>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-sm-12 col-12 d-flex align-items-center justify-content-center"
                    data-aos="fade-up" data-aos-duration="2000">
                    <div class="header-img"></div>
                </div>
            </div>
        </div>
    </section>

    <section class="custom-app">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="title-text">
                        <h3>Custom Application Development</h3>
                    </div>
                    <div class="title-text1">
                        <p>
                            Packaged software often comes with its limitations. ComUnus Technologies Pvt. Ltd. help
                            their customer for custom development projects for specific requirements. We develop robust
                            and customized applications with required features that cater specifically
                            for our customer business needs.
                        </p>
                        <p>
                            ComUnus Development and Integration team is specialized in designing, building, supporting
                            and managing custom business applications. We understand your requirements and develop
                            scalable solutions that fulfil our customer business needs. ComUnus team
                            is competent in this and ensure that our customer investment is optimally leveraged and
                            derive maximum benefit from it.
                        </p>
                        <p>
                            ComUnus team of Analysts and IT Development help our customer business users clearly
                            articulate their requirements and convert them to software specifications.
                        </p>
                    </div>

                    <div class="card-grid">
                        <div class="col-md-4 col-sm-12 col-xs-12 card-data">
                            <mat-card class="example-card">
                                <img mat-card-image src="assets/img/sw1.png" alt="Photo of a Shiba Inu" />
                                <mat-card-content>
                                    <h3 class="title">Application Maintenance</h3>
                                    <p class="title-text1">
                                        ComUnus provide a comprehensive set of application management services including
                                        maintenance, re-engineering, migration and testing. ComUnus help their client in
                                        reduced system downtime, Enhanced performance and Increased freedom to
                                        concentrate on strategic
                                        initiatives. initiatives.
                                    </p>
                                </mat-card-content>
                            </mat-card>
                        </div>

                        <div class="col-md-4 col-sm-12 col-xs-12">
                            <mat-card class="example-card">
                                <img mat-card-image src="assets/img/sw2.png" alt="Photo of a Shiba Inu" />
                                <mat-card-content>
                                    <h3 class="title">Application Re-engineering</h3>
                                    <p class="title-text1">
                                        ComUnus provide Application Re-engineering through re-use the code in already
                                        invested legacy applications, we understand the pain points in legacy
                                        applications and perform source application portfolio analysis / reverse
                                        engineering to create use cases
                                        for target application.
                                    </p>
                                </mat-card-content>
                            </mat-card>
                        </div>

                        <div class="col-md-4 col-sm-12 col-xs-12">
                            <mat-card class="example-card">
                                <img mat-card-image src="assets/img/sw3.png" alt="Photo of a Shiba Inu" />
                                <mat-card-content>
                                    <h3 class="title">Integrations</h3>
                                    <p class="title-text1">
                                        ComUnus help their customer to implement and maintain EAI, ESB & SOA to achieve
                                        Interoperability, Data integration, Robustness, Stability, and Scalability for
                                        their modular application infrastructure.
                                    </p>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- OUR INDUSTRY SECTION -->
    <app-our-services></app-our-services>
</main>
