<section class="services-grid">
    <div class="container">
        <div class="row">
            <div class="our-ind-header text-center">
              <div class="col-md-12">
                <h2 class="common-blue">Our Company Value</h2>
              </div>
            </div>
            <div class="col-md-12" style="padding-top: 4%">
                <owl-carousel-o [options]="customOptions">
                    <ng-template carouselSlide>
                        <div class="col-md-3">
                            <div class="our-comp-img-content-card mt-3 ">
                                <div>
                                    <div class="common-orange">
                                        <img src="assets/img/social-care.png"  class="mx-auto d-block" />
                                    </div>
                                    <h5 class="common-blue m-0">Transparency</h5>
                                    <p>
                                        Openness with our team and clients. We raise issues for the enhancement of client business and provide solutions promptly
                                    </p>
                                </div>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template carouselSlide>
                        <div class="col-md-3">
                            <div class="our-comp-img-content-card ">
                                <div>
                                    <div class="common-orange">
                                        <img src="assets/img/Commitment.png"  class="mx-auto d-block" />
                                    </div>
                                    <h5 class="common-blue m-0">Commitment</h5>
                                    <p>
                                        Our word and hard work over everything else. Our client and vision are our guiding beacons.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template carouselSlide>
                        <div class="col-md-3">
                            <div class="our-comp-img-content-card mt-3 ">
                                <div>
                                    <div class="common-orange">
                                        <img src="assets/img/Self-Improvement.png"  class="mx-auto d-block" />
                                    </div>
                                    <h5 class="common-blue m-0">Self Improvement</h5>
                                    <p>
                                        Never Stop learning, never stop evolving into who we want to be where we want to be.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="col-md-3">
                            <div class="our-comp-img-content-card">
                                <div>
                                    <div class="common-orange">
                                        <img src="assets/img/human.png"  class="mx-auto d-block" />
                                    </div>
                                    <h5 class="common-blue m-0">Our Deliver</h5>
                                    <p>
                                        Deliver more value than our clients expect. Every day, over every delivery.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
