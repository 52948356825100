 <!-- OUR INDUSTRY SECTION -->
 <section class="services">
    <div class="container">
        <div class="row">
            <div class="our-ind-header text-center">
                <h3 class="title-text">Our Services</h3>
                <p class="title-text-ser">
                    We bring IT Solutions and Services with deep domain and industry experience to build unique perspectives and meet the challenging requirements of global clients from diverse industries like Banking & Finance, Insurance, Automobiles, Fintech, E-Commerce
                </p>
            </div>
            <div class="col-md-12" style="padding-top: 4%">
                <owl-carousel-o [options]="customOptions">
                    <ng-template carouselSlide>

                            <div class="exp-box">
                                <img src="assets/img/Group-87.png" />
                                <h3 class="title-text">Software Development</h3>
                                <p>
                                    Packaged software often comes with its limitations. ComUnus Technologies Pvt. Ltd. help their customer for custom development projects for specific requirements.
                                </p>
                                <button class="read-exp d-flex align-items-center btn-grid" routerLink="/software-development">
                                    Read More<mat-icon> arrow_right_alt </mat-icon>
                                </button>
                            </div>

                    </ng-template>
                    <ng-template carouselSlide>

                            <div class="exp-box">
                                <img src="assets/img/smartphone.png" style="width: 14%" />
                                <h3 class="title-text">Mobility Solutions</h3>
                                <p>
                                    Mobile technologies today have untethered businesses from their locations, unlocked immense business value, and unleashed innovative solutions to help businesses grow.
                                </p>
                                <button class="read-exp d-flex align-items-center" routerLink="/mobility-solutions">
                                    Read More<mat-icon> arrow_right_alt </mat-icon>
                                </button>
                            </div>

                    </ng-template>

                    <ng-template carouselSlide>

                            <div class="exp-box">
                                <img src="assets/img/Group-144.png" />
                                <h3 class="title-text">Digital Transformation</h3>
                                <p>
                                    We have observed that industry has undergone a wide range of transitions from being resource- pivotal to budget- pivotal, and finally to become customer- pivotal.
                                </p>
                                <button class="read-exp d-flex align-items-center" routerLink="/digital-transformation">
                                        Read More<mat-icon> arrow_right_alt </mat-icon>
                                    </button>
                            </div>

                    </ng-template>
                    <ng-template carouselSlide>

                            <div class="exp-box">
                                <img src="assets/img/Group-143.png" style="width: 18%" />
                                <h3 class="title-text">IT Infra And Cloud</h3>
                                <p>
                                    Infrastructure is a very basic requirement to build anything. ComUnus expertise lies in IT Infrastructure services like Operating System and Middleware Technologies.
                                </p>
                                <button class="read-exp d-flex align-items-center" routerLink="/it-infra-cloud">
                                    Read More<mat-icon> arrow_right_alt </mat-icon>
                                </button>
                            </div>

                    </ng-template>

                    <ng-template carouselSlide>

                            <div class="exp-box">
                                <img src="assets/img/icon-mobility.png" style="width: 14%" />
                                <h3 class="title-text">Consulting & Managed Services</h3>
                                <p>
                                    We, at ComUnus, provide specialized Application Maintenance &amp; Support (AMS) services to help you leverage your investments in your applications.
                                </p>
                                <button class="read-exp d-flex align-items-center btn-grid" routerLink="/consulting-managed-service">
                                    Read More<mat-icon> arrow_right_alt </mat-icon>
                                </button>
                            </div>

                    </ng-template>
                    <ng-template carouselSlide>

                            <div class="exp-box">
                                <img src="assets/img/Group-102.png" />
                                <h3 class="title-text">Quality Assurance</h3>
                                <p>
                                    Quality Assurance is any systematic process of checking to see whether a product or service being developed is meeting specified requirements.
                                </p>
                                <button class="read-exp d-flex align-items-center btn-grid" routerLink="/qa-testing">
                                        Read More<mat-icon> arrow_right_alt </mat-icon>
                                    </button>
                            </div>

                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
