<section class="topimg about-header">
    <div class="container">
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-sm-12 col-12 d-flex align-items-center justify-content-center" data-aos="fade-up" data-aos-duration="2000">
                <div>
                    <h3 class="title-text">Digital Transformation</h3>
                    <p class="title-text1">
                        We have observed that industry has undergone a wide range of transitions from being resource- pivotal to budget- pivotal, and finally to become customer- pivotal.
                    </p>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-sm-12 col-12 d-flex align-items-center justify-content-center" data-aos="fade-up" data-aos-duration="2000">
                <div class="header-img"></div>
            </div>
        </div>
    </div>
</section>

<section class="custom-app">
    <div class="container">
        <div class="row">
            <div class="col-md-5">
                <img src=" assets/img/Digital-transformation1.png" class="img-fluid" />
            </div>
            <div class="col-md-7">
                <div class="title-text1" style="padding-top: 2%">
                    <p>
                        Adopt an exceptionally effortless digital transformation with ComUnus Technologies Pvt. Ltd.
                    </p>
                    <p>
                        We have observed that industry has undergone a wide range of transitions from being resource- pivotal to budget- pivotal, and finally to become customer- pivotal.
                    </p>
                    <p>
                        We understand that strategic assessment is key to the success of any process. ComUnus digital transformation assessment involves the assessment of market dynamics and technology opportunities for achieving desired results in digital journey.
                    </p>
                    <p>
                        We enable enterprises to drive accessibility, scalability, and consistency.
                    </p>
                </div>
            </div>

            <div class="dig-grid">
                <div class="col-md-6">
                    <div class="title-text1">
                        <p>
                            We utilize the expertise in building in-depth digital strategies and market-ready solutions to unlock phenomenal digital possibilities for you.
                        </p>
                        <p>
                            In today's digital era, we believe in re-imagining value optimization and business continuity.
                        </p>
                        <p>
                            Main benefit from Digital Transformation Consulting with ComUnus.
                        </p>
                        <ul>
                            <li>
                                Eliminate paper and manual data records while centralizing information digitally for easy access.
                            </li>
                            <li>
                                Automating processes cuts down time and increases efficiency in operational processes.
                            </li>
                            <li>
                                Agile delivery approach and cloud for higher value to users and organizations. Deliver enhanced customer experience with data-driven insights.
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-6">
                    <img src=" assets/img/digital-transformation2.png" class="img-fluid" />
                </div>
            </div>
        </div>
    </div>
</section>

<!-- OUR INDUSTRY SECTION -->
<app-our-services></app-our-services>
