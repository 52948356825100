import { MatButtonModule } from '@angular/material/button';
import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('valueChanged', [
      transition('void => *', []), // when the item is created
      transition('* => void', [
        style({
          opacity: 1,
        }),
        animate(
          '1s ease-out',
          style({
            opacity: 0,
          })
        ),
      ]), // when the item is removed
      transition('* => *', [
        // when the item is changed
        style({
          opacity: 0,
        }),
        animate(
          '1s ease-in',
          style({
            opacity: 1,
          })
        ),
      ]),
    ]),
  ],
})
export class HomeComponent implements OnInit {
  happyClient:number = 0;
  projectHours:number = 0;
  teamStrength:number = 0;
  tech:number = 0;

  happyClientStop:any = setInterval(()=>{
    this.happyClient++;
    if(this.happyClient==30)
    {
      clearInterval(this.happyClientStop);
    }
  },100)

  projectHourStop:any = setInterval(()=>{
    this.projectHours++;
    if(this.projectHours==3)
    {
      clearInterval(this.projectHourStop);
    }
  },1000)

  teamStrengthStop:any = setInterval(()=>{
    this.teamStrength++;
    if(this.teamStrength==175)
    {
      clearInterval(this.teamStrengthStop);
    }
  },50)

  techStop:any = setInterval(()=>{
    this.tech++;
    if(this.tech==30)
    {
      clearInterval(this.techStop);
    }
  },100)

  years = 8;

  galleryImages = [
    'assets/img/com-img15.jpg',
    'assets/img/com-img1.jpg',
    // 'assets/img/com-img2.jpg',
    'assets/img/com-img3.jpg',
    'assets/img/com-img4.jpg',
    'assets/img/com-img5.jpg',
    'assets/img/com-img6.jpg',
    'assets/img/com-img7.jpg',
    'assets/img/com-img9.jpg',
    'assets/img/com-img8.jpg',
    'assets/img/com-img9.jpg',
    'assets/img/com-img10.jpg',
    'assets/img/com-img11.jpg',
    'assets/img/com-img12.jpg',
    'assets/img/com-img13.jpg',
    'assets/img/com-img14.jpg',

    'assets/img/com-img16.jpg',
  ];
  activeGalleryImages = [];

  sliderInterval = 5;

  homeSliders = [
    {
      title: 'Your Imagination...<br>Our Solutions...',

      subtitle:
        ' ComUnus have a remarkable tendency for turning your business imaginations into technical realities with our Technology and Domain experience. ',
      img: 'assets/img/slider1.png',
      link: '/consulting-managed-service',
      button: 'Let Explore',
      // method: () => this.meth1()
    },
    {
      title: 'Application Development<br>Technology Matters',
      subtitle:
        'We deliver, End to End Application Development with our extensive experience and well equipped knowledge for your business needs.',
      img: 'assets/img/slider2.png',
      link: '/software-development',
      button: 'Let Explore',
    },
    {
      title: 'Mobility Solutions<br>Acquires ToFu & BoFu',
      subtitle:
        'Hassle free, Handy and easy to use cross platform Mobile Apps which help you acquire top-of-the-funnel (ToFu) and bottom-of-the-funnel (BoFu) users.',
      img: 'assets/img/slider3.png',
      link: '/mobility-solutions',
      button: 'Let Explore',
    },
    {
      title: 'IT Infra And Cloud<br>You Name IT...',
      subtitle:
        'We manage ANYTHING and EVERYTHING, our expertise lies in Clouds, Middleware, Databases, OS, ITSM, OEM, and EUS support/services.',
      img: 'assets/img/slider4.png',
      link: '/it-infra-cloud',
      button: 'Let Explore',

    },
  ];

  homeSliderObj = {
    title: '',
    subtitle: '',
    img: '',
    button:'',
    link:''

  };
  sliderCounter: number = 1;
  constructor() {}

  ngOnInit(): void {
    this.homeSliderObj = this.homeSliders[0];
    this.activeGalleryImages = this.galleryImages.slice(0, 4);
    this.startHomeSlider();
    this.startGallerySlider();
  }
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    margin: 20,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 2,
      },
      940: {
        items: 3,
      },
    },
    nav: true,
  };

  startHomeSlider() {
    setInterval(() => {
      if (this.sliderCounter >= this.homeSliders.length) {
        this.sliderCounter = 0;
      }
      this.homeSliderObj = this.homeSliders[this.sliderCounter++];
    }, this.sliderInterval * 1000);
  }

  startGallerySlider() {
    setInterval(() => {
      this.activeGalleryImages = this.galleryImages
        .sort(() => Math.random() - Math.random())
        .slice(0, 4);
    }, this.sliderInterval * 1000);
  }

  getAboutUsImg(idx) {
    return this.activeGalleryImages[idx];
  }
}
