<header>
  <div id="topheader">
    <nav class="navbar navbar-expand-xl navbar-dark btco-hover-menu fixed-top bg-white">
      <div class="container">
        <a class="navbar-brand" routerLink="">
          <img src="assets/img/logo.png" />
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse"  (click)="toggleMenu()"
          aria-haspopup="true" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="navbar-collapse" >
          <ul class="navbar-nav navbars" *ngIf="menuToggle ||  !isDevice()" >
            <!-- <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" routerLink="">Home
            </a></li> -->
            <li class="nav-item" (click)="toggleMenu()">
              <a class="nav-link" routerLinkActive="active"  routerLink="/about">About Us</a>
            </li>
            <li class="nav-item dropdown" routerLinkActive="active"  >
              <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                Services
              </a>
              <ul class="dropdown-menu" data-aos="fade-up" data-aos-duration="0s"
                aria-labelledby="navbarDropdownMenuLink" style="left: -47%">
                <li (click)="toggleMenu()">
                  <a class="dropdown-item" routerLinkActive="active" routerLink="/software-development"><i
                      class="fa fa-angle-right"></i> Software Development</a>
                </li>
                <li (click)="toggleMenu()">
                  <a class="dropdown-item" routerLinkActive="active" routerLink="/mobility-solutions"><i
                      class="fa fa-angle-right"></i> Mobility Solutions</a>
                </li>
                <li (click)="toggleMenu()">
                  <a class="dropdown-item" routerLink="/digital-transformation"><i class="fa fa-angle-right"></i>
                    Digital Transformation
                  </a>
                </li>
                <li (click)="toggleMenu()">
                  <a class="dropdown-item" routerLink="/it-infra-cloud"><i class="fa fa-angle-right"></i> IT Infra &amp;
                    Cloud</a>
                </li>
                <li (click)="toggleMenu()">
                  <a class="dropdown-item" routerLink="/consulting-managed-service"><i class="fa fa-angle-right"></i>
                    Consulting &amp; Managed
                    Services</a>
                </li>
                <li (click)="toggleMenu()">
                  <a class="dropdown-item" routerLink="/qa-testing"><i class="fa fa-angle-right"></i> QA &amp;
                    Testing</a>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown" routerLinkActive="active" >
              <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                Clientele
              </a>
              <ul class="dropdown-menu animated fadeIn" data-wow-duration="0s" aria-labelledby="navbarDropdownMenuLink"
                style="left: -22%">
                <li (click)="toggleMenu()" >
                  <a class="dropdown-item" routerLink="/banking-finance"><i class="fa fa-angle-right"></i>Banking &amp;
                    Finance</a>
                </li>
                <li (click)="toggleMenu()">
                  <a class="dropdown-item" routerLink="/insurance"><i class="fa fa-angle-right"></i> Insurance</a>
                </li>
                <li (click)="toggleMenu()">
                  <a class="dropdown-item" routerLink="/fintech"><i class="fa fa-angle-right"></i>Fintech</a>
                </li>
                <li (click)="toggleMenu()">
                  <a class="dropdown-item" routerLink="/ecommerce-retail"><i class="fa fa-angle-right"></i>E-Commerce
                    &amp; Retail</a>
                </li>
              </ul>
            </li>
            <li class="nav-item" (click)="toggleMenu()">
              <a class="nav-link" aria-haspopup="true" routerLinkActive="active" routerLink="/current-opening">
                Careers
              </a>
            </li>
            <li class="nav-item" (click)="toggleMenu()">
              <a class="nav-link" routerLinkActive="active" routerLink="/contact">Contact Us</a>
            </li>
          </ul>
        </div>
        <div class="social-links1 d-lg-block d-md-none d-sm-none d-none">
          <ul>
            <li>
              <a href="https://www.linkedin.com/company/comunus-technolgies-pvt-ltd" target="_blank">
                <img src="assets/img/linkedin.png" />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/comunustechnologies" target="_blank"><img
                  src="assets/img/FB.png" /></a>
            </li>
            <li>
              <a href="https://www.instagram.com/comunustechnologies/?igshid=MDM4ZDc5MmU%3D" target="_blank">
                <img src="assets/img/instagram.png" />
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UCKAu4gTMgDklr3bymCXBpAQ" target="_blank">
                <img src="assets/img/youtube.png" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/hashtag/comunustechnologiespvtltd" target="_blank">
                <img src="assets/img/twitter.png" style="width: 63%;" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</header>
