<section class="topimg about-header">
  <div class="container">
    <div class="row">
      <div
        class="col-xl-6 col-lg-6 col-sm-12 col-12 d-flex align-items-center justify-content-center"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div>
          <h3 class="title-text2">
            Grow your Business & Customer Satisfaction with ComUnus
          </h3>
          <p class="title-text2">
            ComUnus word comes from community, which joins two Latin word Com
            ('Together’) and Unus ('One'). A community then is both one any
            many.
          </p>
        </div>
      </div>
      <div
        class="col-xl-6 col-lg-6 col-sm-12 col-12 d-flex align-items-center justify-content-center"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div class="header-img"></div>
      </div>
    </div>
  </div>
</section>

<section class="address-map">
  <div class="container">
    <div class="row">
      <div class="offset-md-1 col-md-10">
        <div class="info-grid">
          <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d3767.9323943361364!2d72.94645551437854!3d19.19815495311151!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3be7b90855692f21%3A0x794a27f08ac394c1!2sComUnus%20Technologies%20Pvt%20Ltd%2C%20710%2C%20Lodha%20Supremus%2C%20Rd%20Number%2022%2C%20Wagle%20Estate%2C%20Kisan%20Nagar%201%2C%20Thane%20West%2C%20Thane%2C%20Maharashtra%20400604!3m2!1d19.198152399999998!2d72.9488154!5e0!3m2!1sen!2sin!4v1614951865078!5m2!1sen!2sin"
              width="100%"
              height="400"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>
          <div
            class="col-lg-6 col-md-12 col-sm-12 col-xs-12"
            style="padding-left: 7%"
          >
            <h3 class="title-add">Address Details</h3>
            <div class="info">
              <div class="contact-icon">
                <mat-icon> phone</mat-icon>
              </div>
              <div class="contact-detail">
                <label>Phone</label>
                <span>+91-8291182201</span>
              </div>
            </div>

            <div class="info">
              <div class="contact-icon">
                <mat-icon> email</mat-icon>
              </div>
              <div class="contact-detail">
                <label>Email Address</label>
                <span>info@comunus.in</span>
              </div>
            </div>

            <div class="info">
              <div class="contact-icon">
                <mat-icon> location_on</mat-icon>
              </div>
              <div class="contact-detail">
                <label>Office Address</label>
                <span
                  >902, 9th Floor, Oriana Business Park, Road No.22, Wagle Estate, Thane,
                  Mumbai, Maharashtra - 400604.</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section style="padding-bottom: 5%">
  <div class="container">
    <div class="row">
      <div class="offset-md-1 col-md-10">
        <div class="get-in-touch">
          <h3>Get in Touch</h3>
          <p>
            Reach out to us from our contact form and we will get back to you
            shortly
          </p>
          <!-- <div class="col-md-12"> -->
          <div class="contact-form">
            <form [formGroup]="contactUsFormGrp">
              <div class="row">
                <div class="col-md-6">
                  <mat-form-field
                    appearance="outline"
                    class="mat-form-field-wrapper"
                  >
                    <mat-label>Name </mat-label>
                    <input
                      matInput
                      placeholder="Placeholder"
                      formControlName="name"
                      maxlength="50"
                      required
                    />
                    <mat-error *ngIf="!contactUsFormGrp.controls['name'].valid">
                      {{ getErrorMessage("name") }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field
                    appearance="outline"
                    class="mat-form-field-wrapper"
                  >
                    <mat-label>Email Id *</mat-label>
                    <input
                      matInput
                      placeholder="Placeholder"
                      formControlName="emailid"
                      maxlength="30"
                    />
                    <mat-error
                      *ngIf="!contactUsFormGrp.controls['emailid'].valid"
                    >
                      {{ getErrorMessage("emailid") }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field
                    appearance="outline"
                    class="mat-form-field-wrapper"
                  >
                    <mat-label>Mobile No *</mat-label>
                    <input
                      matInput
                      placeholder="Placeholder"
                      formControlName="mobile"
                      maxlength="10"
                    />
                    <mat-error
                      *ngIf="!contactUsFormGrp.controls['mobile'].valid"
                    >
                      {{ getErrorMessage("mobile") }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field
                    appearance="outline"
                    class="mat-form-field-wrapper"
                  >
                    <mat-label>Subject *</mat-label>
                    <input
                      matInput
                      placeholder="Placeholder"
                      formControlName="subject"
                      maxlength="100"
                    />
                    <mat-error
                      *ngIf="!contactUsFormGrp.controls['subject'].valid"
                    >
                      {{ getErrorMessage("subject") }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-12">
                  <mat-form-field
                    appearance="outline"
                    style="width: 100%; padding-left: 2%; padding-right: 4%"
                    maxlength="500"
                  >
                    <mat-label>Message</mat-label>
                    <textarea matInput formControlName="message"></textarea>
                    <mat-error
                      *ngIf="!contactUsFormGrp.controls['message'].valid"
                    >
                      {{ getErrorMessage("message") }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-9 sub">
                  <button class="btn" type="button" (click)="submitForm()">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- COUNTER DIV -->
<section id="counter" data-aos="fade-right" data-aos-duration="5000">
  <div class="container">
    <div class="col-md-12">
      <mat-card class="counter-card">
        <h2 class="text-center about-us-title">Our Achievements</h2>
        <mat-card-subtitle class="text-center"
          >Comunus Where Client and Team matter the most</mat-card-subtitle
        >

        <ul class="counter-list">
          <div class="col-xs-6 inline-grid">
            <li class="counter-item list-group-item text-center">
              <h1 class="counter">{{ happyClient }}+</h1>
              <mat-card-subtitle>Happy Client</mat-card-subtitle>
            </li>
            <li class="counter-item list-group-item text-center">
              <h1 class="counter">{{ projectHours }} Lacs</h1>
              <mat-card-subtitle>Project Hours</mat-card-subtitle>
            </li>
          </div>
          <div class="col-xs-6 inline-grid">
            <li class="counter-item list-group-item text-center">
              <h1 class="counter">{{ teamStrength }}+</h1>
              <mat-card-subtitle>Team Strength</mat-card-subtitle>
            </li>
            <li class="counter-item list-group-item text-center">
              <h1 class="counter">{{ tech }}+</h1>
              <mat-card-subtitle>Technology & Skills</mat-card-subtitle>
            </li>
          </div>
        </ul>
      </mat-card>
    </div>
  </div>
</section>
