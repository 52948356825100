<section class="topimg about-header">
  <div class="container">
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-sm-12 col-12 d-flex align-items-center justify-content-center"
        data-aos="fade-up" data-aos-duration="2000">
        <div>
          <h3 class="title-text">Careers</h3>
          <p class="title-text1">
            Join us in this exciting journey in making ComUnus a dream company to work with and also creating customer
            delight by adding immense value to the business.
          </p>
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-sm-12 col-12 d-flex align-items-center justify-content-center"
        data-aos="fade-up" data-aos-duration="2000">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/Q0aCbtzb_EM?si=mlxiKvB_tsjzc3Un&loop=1&autoplay=1&showinfo=0" frameborder="0" allowfullscreen>
        </iframe>      </div>
    </div>
  </div>
</section>
<section class="Content">
  <div class="container">
    <div class="row">
      <div class="col-md-12 pt-3 pt-3">
        <h3 class="title-text">Why ComUnus</h3>
        <p class="title-text1">
          At ComUnus Technologies Pvt Ltd, you will get to experience an environment that has the latest technology,
          colleagues with diverse views, a high risk-reward culture. Many people find this culture to be highly
          interesting, extremely learning oriented and fun too!
        </p>
        <p class="title-text1">
          As one of the fastest growing organization,ComUnus believe in delivering tangible results to our customers in
          a cost-effective manner.You'll find a positive and passionate work culture.
        </p>
        <p class="title-text1">ComUnus welcome you to be a part of our journey....!!!! </p>
      </div>

      <div class="col-md-12 pt-3 pt-3">
        <h3 class="title-text">Current Openings</h3>
        <p class="title-text1">
          ComUnus Technologies Pvt. Ltd. offers great career opportunities across the globe.
        </p>
        <p class="title-text1">
          Join us in this exciting journey in making ComUnus a dream company to work with and also creating customer
          delight by adding immense value to the business.
        </p>
      </div>
      <!-- <button class="common-career-footer-card-btn"> <a href="http://13.235.81.101/online_candidate_form" target="_blank"> Apply Now <mat-icon>arrow_right_alt</mat-icon></a></button> -->


            <div class="col-md-12">
                <mat-accordion>
                  <div *ngFor="let jd of jds">
                    <mat-expansion-panel class="panel" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                      <mat-expansion-panel-header>
                          <mat-panel-title>
                              <!-- <img src="../../assets/img/java.png"> -->
                              <div class="head">
                                {{jd.jobTitle}}
                                 <p> {{jd.jobContent}} </p>
                              </div>
                          </mat-panel-title>
                      </mat-expansion-panel-header><br>
                      <h4 class="heading">{{jd.jobContent}} </h4>
                      <div class="desc" >
                      <ul>
                        <li *ngFor="let item of jd.jdList">{{item}}</li>
                      </ul>
                  </div>
                  <!-- <button class="common-career-footer-card-btn"><a href="mailto:heramb.limaye@comunus.in" target="_blank"> Apply Now <mat-icon>arrow_right_alt</mat-icon></a></button> -->
                  <a [href]="jd.applyUrl" class="common-career-footer-card-btn btn" target="_blank"> Apply Now</a>

                </mat-expansion-panel>
                  </div>
                </mat-accordion>

            </div>
        </div>
        <div class="col-12 pl-0">
          <h6 class="title-text1">
            Note : If you are facing any issue in applying online, you can also mail your details with Updated Resume, Current CTC, Expected CTC, Notice Period with Job Code mentioned in Subject Line to
            <a href="mailto:career@comunus.in" target="_blank">careers@comunus.in</a>
          </h6>

        </div>

    </div>

</section>
