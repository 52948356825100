<footer id="footer" >
    <div data-aos="fade-right" data-aos-duration="5000">
        <div class="container">
            <mat-card *ngIf="isCurrentOpeningVisible" class="common-career-footer-card d-flex align-items-center">
                <div class="c-footer">
                    <p class="m-0">Career Opportunities</p>
                    <h4 class="m-0">We always prioritize newcomers!</h4>
                    <button class="common-career-footer-card-btn" routerLink="/current-opening"> Apply Now </button>

                </div>
            </mat-card>
        </div>
    </div>
    <div class="footer-top">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-3">
                    <div class="footer-link">
                        <h4>Company</h4>
                        <ul>
                            <li><a routerLink="/about">About Us </a></li>
                            <li><a routerLink="/current-opening">Careers </a></li>
                            <li><a routerLink="/contact">Contact Us </a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3">
                    <div class="footer-link">
                        <h4>Services</h4>
                        <ul>
                            <li>
                                <a routerLink="/software-development">Software Development </a>
                            </li>
                            <li>
                                <a routerLink="/mobility-solutions">Mobility Solutions</a>
                            </li>
                            <li>
                                <a routerLink="/digital-transformation">Digital Transformation </a>
                            </li>
                            <li><a routerLink="/it-infra-cloud">IT Infra & Cloud</a></li>
                            <li> <a routerLink="/consulting-managed-service">Consulting & Managed Services</a> </li>
                            <li><a routerLink="/qa-testing">Quality Assurance</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3">
                    <div class="footer-link">
                        <h4>Clientele</h4>
                        <ul>
                            <li><a routerLink="/banking-finance">Banking & Finance </a></li>
                            <li><a routerLink="/insurance">Insurance</a></li>
                            <li><a routerLink="/fintech">Fintech </a></li>
                            <li><a routerLink="/ecommerce-retail">E-Commerce & Retail</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-12 footer-contact">
                    <h4>Contact Us</h4>
                    <div class="row">
                        <!-- <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                        </div> -->
                        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10">
                            <p>
                              902, 9th Floor, Oriana Business Park, Road No.22, Wagle Estate, Thane,
                              Mumbai, Maharashtra - 400604.
                            </p>
                            <p class="DIRECTIONS">
                                <a href="https://www.google.com/maps/dir//ComUnus+Technologies+Pvt+Ltd,+710,+Lodha+Supremus,+Rd+Number+22,+Wagle+Estate,+Kisan+Nagar+1,+Thane+West,+Thane,+Maharashtra+400604/@19.19815,72.948644,17z/data=!4m15!1m6!3m5!1s0x0:0x794a27f08ac394c1!2sComUnus+Technologies+Pvt+Ltd!8m2!3d19.1981499!4d72.9486442!4m7!1m0!1m5!1m1!1s0x3be7b90855692f21:0x794a27f08ac394c1!2m2!1d72.9486442!2d19.1981499?hl=en-IN"
                                    target="_blank"><strong>GET DIRECTIONS</strong></a>
                            </p>
                        </div>
                        <!-- <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                            <i class="fa fa-envelope" aria-hidden="true"></i>
                        </div> -->
                        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10">
                            <a href="mailto:info@comunus.in">
                                <p>info@comunus.in</p>
                            </a>
                        </div>
                        <!-- <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                            <i class="fa fa-phone" aria-hidden="true"></i>
                        </div> -->
                        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10">
                            <a href="tel:+918291182201">
                                <p>+91 8291182201</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<div class="copyright">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <p> &copy; 2024 |<strong> ComUnus Technologies Pvt Ltd</strong>. All Rights Reserved. </p>
            </div>
            <div class="col-md-6">
                <div class="social-links1 d-lg-block d-md-none d-sm-none d-none">
                    <ul>
                        <li>
                            <a href="https://www.linkedin.com/company/comunus-technolgies-pvt-ltd" target="_blank">
                                <img src="assets/img/footer-lin.png" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/comunustechnologies" target="_blank"><img
                                    src="assets/img/footer-facebook.png" /></a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/comunustechnologies/?igshid=MDM4ZDc5MmU%3D"
                                target="_blank">
                                <img src="assets/img/footer-instagram.png" />
                            </a>
                        </li>
                                <li>
                            <a href="https://www.youtube.com/channel/UCKAu4gTMgDklr3bymCXBpAQ" target="_blank">
                                <img src="assets/img/footer-youtube.png" />
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/hashtag/comunustechnologiespvtltd" target="_blank">
                                <img src="assets/img/twitter-white.png" style="width: 78%;" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!--mobile view-->
<footer id="footer1">
    <div data-aos="fade-right" data-aos-duration="5000">
        <div class="container">
            <mat-card   *ngIf="isCurrentOpeningVisible" class="common-career-footer-card d-flex align-items-center">
                <div class="c-footer">
                    Career Opportunities<br>
                    <p>We always prioritize newcomers!</p>
                    <!-- <h4 class="m-0"></h4> -->
                    <button class="common-career-footer-card-btn" routerLink="/current-opening"> Apply Now </button>

                </div>
            </mat-card>
        </div>
    </div>
    <div class="footer-top">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <!-- <div class="container"> -->
                    <div class="accordion" id="exampleAccordion" style="padding-top: 10%;">
                        <div class="card">
                            <div class="card-header" id="exItem1Header">
                                <h5 class="mb-0">
                                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                                        data-target="#exItem1" aria-expanded="false" aria-controls="exItem1">Company
                                        <mat-icon>keyboard_arrow_down</mat-icon></button>
                                </h5>
                            </div>
                            <div id="exItem1" class="collapse show" aria-labelledby="exItem1Header"
                                data-parent="#exampleAccordion">
                                <ul>
                                    <li><a routerLink="/about">About Us </a></li>
                                    <li><a routerLink="/current-opening">Careers </a></li>
                                    <li><a routerLink="/contact">Contact Us </a></li>
                                </ul>
                            </div>
                        </div>

                        <div class="card">
                            <div class="card-header" id="exItem2Header">
                                <h5 class="mb-0">
                                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                                        data-target="#exItem2" aria-expanded="false" aria-controls="exItem2">Services
                                        <mat-icon>keyboard_arrow_down</mat-icon></button>
                                </h5>
                            </div>
                            <div id="exItem2" class="collapse" aria-labelledby="exItem2Header"
                                data-parent="#exampleAccordion">
                                <ul>
                                    <li>
                                        <a routerLink="/software-development">Software Development </a>
                                    </li>
                                    <li>
                                        <a routerLink="/mobility-solutions">Mobility Solutions</a>
                                    </li>
                                    <li>
                                        <a routerLink="/digital-transformation">Digital Transformation </a>
                                    </li>
                                    <li><a routerLink="/it-infra-cloud">IT Infra & Cloud</a></li>
                                    <li> <a routerLink="/consulting-managed-service">Consulting & Managed Services</a>
                                    </li>
                                    <li><a routerLink="/qa-testing">Quality Assurance</a></li>
                                </ul>
                            </div>
                        </div>

                        <div class="card">
                            <div class="card-header" id="exItem3Header">
                                <h5 class="mb-0">
                                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                                        data-target="#exItem3" aria-expanded="false" aria-controls="exItem3">Clientele
                                        <mat-icon>keyboard_arrow_down</mat-icon></button>
                                </h5>
                            </div>
                            <div id="exItem3" class="collapse" aria-labelledby="exItem3Header"
                                data-parent="#exampleAccordion">
                                <ul>
                                    <li><a routerLink="/banking-finance">Banking & Finance </a></li>
                                    <li><a routerLink="/insurance">Insurance</a></li>
                                    <li><a routerLink="/fintech">Fintech </a></li>
                                    <li><a routerLink="/ecommerce-retail">ECommerce & Retail</a></li>
                                </ul>
                            </div>
                        </div>
                        <div style="padding-left: 4%;">
                            <h4>Contact Us</h4>
                            <div class="row">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <p>
                                      902, 9th Floor, Oriana Business Park, Road No.22, Wagle Estate, Thane,
                                      Mumbai, Maharashtra - 400604.
                                    </p>
                                    <li class="DIRECTIONS">
                                        <a href="https://www.google.com/maps/dir//ComUnus+Technologies+Pvt+Ltd,+710,+Lodha+Supremus,+Rd+Number+22,+Wagle+Estate,+Kisan+Nagar+1,+Thane+West,+Thane,+Maharashtra+400604/@19.19815,72.948644,17z/data=!4m15!1m6!3m5!1s0x0:0x794a27f08ac394c1!2sComUnus+Technologies+Pvt+Ltd!8m2!3d19.1981499!4d72.9486442!4m7!1m0!1m5!1m1!1s0x3be7b90855692f21:0x794a27f08ac394c1!2m2!1d72.9486442!2d19.1981499?hl=en-IN"
                                        target="_blank"><strong>GET DIRECTIONS</strong></a>
                                    </li>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <a href="mailto:info@comunus.in">
                                        <p>info@comunus.in</p>
                                    </a>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <a href="tel:+918291182201">
                                        <p>+91 8291182201</p>
                                    </a>


                                </div>
                                <div  class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                  <div class="social-links1 ml-0">
                                    <ul>
                                        <li>
                                            <a href="https://www.linkedin.com/company/comunus-technolgies-pvt-ltd" target="_blank">
                                                <img src="assets/img/footer-lin.png" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.facebook.com/comunustechnologies" target="_blank"><img
                                                    src="assets/img/footer-facebook.png" /></a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/comunustechnologies/?igshid=MDM4ZDc5MmU%3D"
                                                target="_blank">
                                                <img src="assets/img/footer-instagram.png" />
                                            </a>
                                        </li>
                                                <li>
                                            <a href="https://www.youtube.com/channel/UCKAu4gTMgDklr3bymCXBpAQ" target="_blank">
                                                <img src="assets/img/footer-youtube.png" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://twitter.com/hashtag/comunustechnologiespvtltd" target="_blank">
                                                <img src="assets/img/twitter-white.png" style="width: 78%;" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                </div>
                            </div>
                        </div>

                    </div>


                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</footer>
<div class="copyright1">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <p> &copy; 2024 |<strong> ComUnus Technologies Pvt Ltd</strong>. All Rights Reserved. </p>
            </div>
            <div class="col-md-6">
                <div class="social-links1 d-lg-block d-md-none d-sm-none d-none">
                    <ul>
                        <li>
                            <a href="https://www.linkedin.com/company/comunus-technolgies-pvt-ltd" target="_blank">
                                <img src="assets/img/footer-lin.png" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/comunustechnologies" target="_blank"><img
                                    src="assets/img/footer-facebook.png" /></a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/comunustechnologies/?igshid=MDM4ZDc5MmU%3D"
                                target="_blank">
                                <img src="assets/img/footer-instagram.png" />
                            </a>
                        </li>
                                <li>
                            <a href="https://www.youtube.com/channel/UCKAu4gTMgDklr3bymCXBpAQ" target="_blank">
                                <img src="assets/img/footer-youtube.png" />
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/hashtag/comunustechnologiespvtltd" target="_blank">
                                <img src="assets/img/twitter-white.png" style="width: 78%;" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
