import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  constructor() {}
  menuToggle : boolean = false;

  ngOnInit(): void {
    this.isDevice();
  }

  toggleMenu()
  {
    this.menuToggle = !this.menuToggle;
    console.log("this.menuToggle",this.menuToggle)
  }

  isDevice() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return true;
    } else {
      return false;
    }
  }
}
