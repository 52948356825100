<section class="topimg about-header">
    <div class="container">
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-sm-12 col-12 d-flex align-items-center justify-content-center" data-aos="fade-up" data-aos-duration="2000">
                <div>
                    <h3 class="title-text">E-Commerce Retail</h3>
                    <p class="title-text1">
                        ComUnus word comes from community, which joins two Latin word Com ('together’) and Unus ('one'). A community then is bothone any many.
                    </p>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-sm-12 col-12 d-flex align-items-center justify-content-center" data-aos="fade-up" data-aos-duration="2000">
                <div class="header-img"></div>
            </div>
        </div>
    </div>
</section>

<section class="custom-app">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <img src="assets/img/ecommerce1.png" class="img-fluid" />
            </div>
            <div class="col-md-6">
                <div class="title-text1">

                    <p>
                      Today’s consumers use many different channels to compare and buy products and services. They expect retailers to provide fast, consistent and seamless interactions in stores, online and via mobile devices.
                      </p>
                      <p>
                        ComUnus helps retailers transform their technology to gain a 360-degree view of consumers and serve them across the entire purchase process. With greater visibility into the customer experience, you improve operational efficiencies, increase order fulfillment and encourage collaboration between stores and supply chain partners.
                      </p>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- OUR INDUSTRY SECTION -->
<app-our-services></app-our-services>

