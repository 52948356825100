import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AuthService } from '../auth.service';
// import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-current-opening',
  templateUrl: './current-opening.component.html',
  styleUrls: ['./current-opening.component.scss'],
})
export class CurrentOpeningComponent implements OnInit {
  careerForm: FormGroup;
  selectedFiles: File[] = [];
  token: string | undefined;
  captchaSubs: Subscription = null;

  constructor(
    private auth: AuthService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    // private reCaptchaV3Service: ReCaptchaV3Service,
    private toastr: ToastrService
  ) {
    this.token = undefined;
  }
  panelOpenState = false;
  jds: any;
  ngOnInit(): void {
    this.careerForm = this.fb.group({});
    this.jobDescrption();
  }

  selectFiles(e): void {
    this.selectedFiles = e.target.files;
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.captchaSubs != null) this.captchaSubs.unsubscribe();
  }

  // submitCarrer() {
  //   this.captchaSubs = this.reCaptchaV3Service
  //     .execute('importantAction')
  //     .subscribe((token: string) => {
  //       console.debug(`Token [${token}] generated`);
  //       this.careerForm.controls.recaptcha.setValue(token);
  //     });

  //   if (this.careerForm.invalid || this.selectedFiles.length < 1) {
  //     this.careerForm.markAllAsTouched();
  //     console.log('Career Form', this.careerForm);
  //     console.log('Selected File Length', this.selectedFiles.length);
  //     // show toast message here
  //     return;
  //   }

  //   let body = new FormData();
  //   body.append('file', this.selectedFiles[0]);
  //   body.append('candidateDetails', JSON.stringify(this.careerForm.value));
  //   this.spinner.show();

  //   this.auth.careerApi(body).subscribe(
  //     (res) => {
  //       if (res.errorCode == '200') {
  //         this.toastr.success(
  //           'We will get back to you shortly',
  //           'Job Applied Successfully'
  //         );
  //         this.careerForm.reset({ eventEmit: false });
  //       } else if (res.errorCode == '201') {
  //         this.toastr.success('', res.errorMsg);
  //       } else {
  //         this.toastr.error(
  //           'Please try again later',
  //           'Job Application Failed!'
  //         );
  //       }
  //       this.spinner.hide();
  //     },
  //     (err) => {
  //       this.spinner.hide();
  //       this.toastr.error('Please try again later', 'Something went wrong!');
  //     }
  //   );
  // }

  jobDescrption() {
    // this.auth.jdApi().subscribe((res) => {
    //   // var jdList = res.jdList;

    // });
    var jdList = {
      "1": [
          "Experience in Analyzing, developing, deploying J2EE applications",
          "Java/J2EE development skills, Spring MVC, Spring Core, Spring boot, Web Services REST, HTML5, JavaScript, XML, CSS, AJAX, Oracle/MySQL, Application Build and Deployment.",
          "Quick learner and have excellent communication skills",
          "Ability to troubleshoot and debug critical problems and self-motivated to deep dive in resolving RCA.",
          "Hands on Experience in Java, Advanced java ,JSP Servlets, Core java, spring .",
          "Experience into Restful Web services and Database.",
          "Should be able to work independently.",
          "Should be able to work with team.",
          "Candidate must have experience in Java, Web Services, Database Queries, Spring, JSP, Spring MVC, Hibernate, JPA, Spring Boot."
      ],
      "2": [
          "Candidate must be Graduated in Bcom field.",
          "Should be able to maintain bank statements.",
          "Settle costs on balance sheets.",
          "Should be good in Communication.",
          "Role will be handling Client Details.",
          "Manage Invoice & Billing.",
          "Help the senior accounts team."
      ],
      "3": [
          "Must have good communication skills",
          "Understanding of basic applications flow",
          "Mail Access  / Writing  Skills  is mandatory",
          "Immediate Joiners are preferred"
      ],
      "4": [
          "Should Have Knowledge About The Flow Of Application Support.",
          "Should Have Knowledge Of Sql.",
          "Candidate should able to Create BRD Documents.",
          "Strong SQL Queries, Joints Knowledge, Experience Connecting To DBs And Running Queries,Store Procedure,Views Etc Good Communication Skills. "
      ],
      "5": [
          "End-To-End IT Recruitment. Source Profiles (Especially From LinkedIn,) Naukri, References.",
          "Cold Calling, Negotiation, Lineups.",
          "Able To Work Hard And After Hours Utilizing Different Job Portals, Database, Referrals, Different Social Media, Etc. For Sourcing Relevant Candidates.",
          "Negotiating With Potential Selected Candidates And Providing Offer To Them.",
          "Ensuring Candidates Join As Per Accepted Offer.",
          "Minimum 1 Years Of Experience In IT Recruitment.",
          "Interacting With The Candidates, Briefing Them About Their JD."
      ],
      "6": [
          "Basic Skills : Java / J2EE (Web Services (Rest/ SOAP)/ Java connector Architecture/ EJB/ JMS/ JDBC/ JSP/Servlets).",
          "Frameworks : Spring, Hibernate, Struts/ Spring MVC.",
          "Frontend Technologies : jQuery / JavaScript/ XML / XSLT/HTML.",
          "Application servers : Tomcat & Weblogic /Websphere/Jboss (any one).",
          "Database Skills : SQL/Oracle/MySQL basic and RDBMS concepts, Procedures, views/Functions, Jobs, Triggers.",
          "Maven, ant, JUnit/TestNG."
      ]
  };
  var jobTitle : any = [
    {
        "id": 2,
        "jobTitle": "Account Executive",
        "jobContent": "0 to 1 Years Experience in below Required Skills:",
        "status": "1"
    },
    {
        "id": 6,
        "jobTitle": "Freshers",
        "jobContent": "0 to 1 Years Experience in below Required Skill:",
        "status": "1"
    },
    {
        "id": 5,
        "jobTitle": "HR Recruiter",
        "jobContent": "0 to 1 Years Experience in below Required Skill:",
        "status": "1"
    },
    {
        "id": 1,
        "jobTitle": "Java Developer",
        "jobContent": "2 to 9 Years Experience in below Required Skills:",
        "status": "1"
    },
    {
        "id": 3,
        "jobTitle": "L1 Application Support",
        "jobContent": "0 to 1 Years Experience in below Required Skill:",
        "status": "1"
    },
    {
        "id": 4,
        "jobTitle": "L2 Application Support",
        "jobContent": "2 to 3 Years Experience in below Required Skill:",
        "status": "1"
    },
    {
        "id": 12,
        "jobTitle": "Others",
        "jobContent": "If your technology related not found, apply here & add Position Name.",
        "status": "1"
    }
]
    jobTitle.forEach((val, idx) => {
      val.jdList = jdList[val.id];
      // val.applyUrl = `${res.tagUrl}online_candidate_form?job=${val.id}`;
      val.applyUrl = `http://13.235.81.101/online_candidate_form?job=${val.id}`;

    });
    this.jds = jobTitle;
  }

  getErrorMessage(controlName: string) {}
}
