<section class="topimg about-header">
    <div class="container">
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-sm-12 col-12 d-flex align-items-center justify-content-center" data-aos="fade-up" data-aos-duration="2000">
                <div>
                    <h3 class="title-text">
                        Quality Assurance
                    </h3>
                    <p class="title-text1">
                        Quality Assurance is any systematic process of checking to see whether a product or service being developed is meeting specified requirements.
                    </p>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-sm-12 col-12 d-flex align-items-center justify-content-center" data-aos="fade-up" data-aos-duration="2000">
                <div class="header-img"></div>
            </div>
        </div>
    </div>
</section>

<section class="custom-app">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <img src="assets/img/Quality-assurance.png" class="img-fluid">
            </div>
            <div class="col-md-6">
                <div class="title-text">
                    <h3>Quality Assurance</h3>
                </div>
                <div class="title-text1">
                    <p>
                        Quality Assurance is any systematic process of checking to see whether a product or service being developed is meeting specified requirements. ComUnus help their customers to achieve Quality for all their new or existing products or applications.
                    </p>
                    <p>We at ComUnus Technologies Pvt. Ltd. Help our customer in their Software Testing requirements which helps them to validate and verify that application or product meets the business and technical requirements that guided it’s design and development, works as expected and can be implemented with the same characteristic.
                    </p>

                </div>
            </div>
        </div>
    </div>
</section>

<!-- OUR INDUSTRY SECTION -->
<app-our-services></app-our-services>
