<section class="topimg about-header">
    <div class="container">
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-sm-12 col-12 d-flex align-items-center justify-content-center" data-aos="fade-up" data-aos-duration="2000">
                <div>
                    <h3 class="title-text">
                          Fintech
                    </h3>
                    <p class="title-text1">

                        Financial institutions are also rethinking about their traditional methods and slowly adapting to the Fintech Solutions.                                            </p>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-sm-12 col-12 d-flex align-items-center justify-content-center" data-aos="fade-up" data-aos-duration="2000">
                <div class="header-img"></div>
            </div>
        </div>
    </div>
</section>

<section class="custom-app">
    <div class="container">
        <div class="row">
            <div class="col-md-6 pt-3">
                <img src="assets/img/fintech1.png" class="img-fluid" />
            </div>
            <div class="col-md-6">
                <div class="title-text">
                    <!-- <h3>Fintech</h3> -->
                </div>
                <div class="title-text1">
                   <p>Financial institutions are also rethinking about their traditional methods and slowly adapting to the Fintech Solutions that defines how they are providing their services.
                </p>
                <p>
                    However, the major challenge comes when it is time to find a dependable technology provider that can not only provide you the relief from the redundant methods of operations but also provide you the accuracy and errorless approach you have for years.
                   </p>
                   <p>
                    ComUnus Technologies Pvt. Ltd. helps to find high-end technology and finance management software that allows you to efficiently manage your business anytime and anywhere.
                </p>
                <p>
                    ComUnus works with BFSI industry closely to provide you a class apart technology experience and next-gen Solutions.</p>
                </div>
            </div>
            <div class="dig-grid">
                <div class="col-md-6">
                    <div class="title-text1">
                        <p>
                            We can mobilize a team of seasoned Fintech experts, full-stack software developers, PMs, data scientists, UX / UI designers, Devops specialists to achieve even the most ambitious project goals.
                        </p>

                        <ul>
                            <li>Big Data & Analytics</li>
                             <li>   AI & ML</li>
                             <li>   Cloud & Devops</li>
                             <li>   Business Intelligence</li>
                             <li>   Robotic Process Automation</li>
                             <li>   Strategic Data Storage</li>
                             <li>   Micro-services Architecture</li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-6">
                    <img src="assets/img/fintech2.png" class="img-fluid" />
                </div>
            </div>
        </div>
    </div>
</section>

<!-- OUR INDUSTRY SECTION -->
<app-our-services></app-our-services>
