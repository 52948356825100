import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  NgForm,
  Validators,
} from '@angular/forms';
import { ApiservicesService } from 'src/services/apiservices.service';
import { AuthService } from '../auth.service';
// import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { validateBasis } from '@angular/flex-layout';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  contactUsFormGrp: FormGroup;
  token: string | undefined;
  captchaSubs: Subscription = null;

  happyClient: number = 0;
  projectHours: number = 0;
  teamStrength: number = 0;
  tech: number = 0;

  happyClientStop: any = setInterval(() => {
    this.happyClient++;
    if (this.happyClient == 30) {
      clearInterval(this.happyClientStop);
    }
  }, 100);

  projectHourStop: any = setInterval(() => {
    this.projectHours++;
    if (this.projectHours == 3) {
      clearInterval(this.projectHourStop);
    }
  }, 1000);

  teamStrengthStop: any = setInterval(() => {
    this.teamStrength++;
    if (this.teamStrength == 175) {
      clearInterval(this.teamStrengthStop);
    }
  }, 50);

  techStop: any = setInterval(() => {
    this.tech++;
    if (this.tech == 30) {
      clearInterval(this.techStop);
    }
  }, 100);

  constructor(
    private api: ApiservicesService,
    private spinner: NgxSpinnerService,
    private auth: AuthService,
    private fb: FormBuilder,
    // private reCaptchaV3Service: ReCaptchaV3Service,
    private toastr: ToastrService
  ) {
    this.token = undefined;
  }
  form: any;
  // siteKey:string ="6Lf8eF0lAAAAAPxJ0mufLBT8vR2XEyx6zt3Vl_7R";
  ngOnInit(): void {
    this.contactUsFormGrp = this.fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z ]*$'),
          Validators.maxLength(10),
        ],
      ],
      emailid: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern('[a-z0-9]+@[a-z]+.[a-z]{2,3}'),
        ],
      ],
      mobile: [
        '',
        [
          Validators.required,
          Validators.pattern(/^-?(0|[1-9]\d*)?$/),
          Validators.maxLength(10),
        ],
      ],
      subject: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z ]*$'),
          Validators.maxLength(50),
        ],
      ],
      message: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z ]*$'),
          Validators.maxLength(500),
        ],
      ],
      recaptcha: [''],
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.captchaSubs != null) this.captchaSubs.unsubscribe();
  }

  // onSubmit() {
  //   var tempVal = false;
  //   this.captchaSubs = this.reCaptchaV3Service
  //     .execute('importantAction')
  //     .subscribe((token: string) => {
  //       if (token) {
  //         console.log(token);
  //         this.contactUsFormGrp.controls.recaptcha.patchValue(token);
  //         this.submitForm();
  //       } else {
  //         console.log('NOTOKEN');
  //       }
  //     });
  // }

  submitForm() {
    if (this.contactUsFormGrp.invalid) {
      this.contactUsFormGrp.markAllAsTouched();
      return;
    }
    console.log(this.contactUsFormGrp.value);
    this.spinner.show();
    this.auth.contactApi(this.contactUsFormGrp.value).subscribe(
      (res) => {
        console.log(res);
        if (res != null) {
          this.toastr.success(
            'We will get back to you shortly',
            'Details sent Successfully'
          );
          this.contactUsFormGrp.reset({ eventEmit: false });
        } else {
          this.toastr.error('Please try again later', 'Inavlid Details');
        }
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(
          'Please try again later',
          'Something went wrong!',
          err
        );
      }
    );
  }

  getErrorMessage(controlName: string) {
    if (
      controlName == 'name' &&
      this.contactUsFormGrp.controls[controlName].hasError('required')
    ) {
      return 'Enter Your Name';
    } else if (
      controlName == 'name' &&
      this.contactUsFormGrp.controls[controlName].hasError('pattern')
    ) {
      return 'Enter A Valid Name';
    }
    if (
      controlName == 'emailid' &&
      this.contactUsFormGrp.controls[controlName].hasError('required')
    ) {
      return 'Enter the email id';
    } else if (
      controlName == 'emailid' &&
      this.contactUsFormGrp.controls[controlName].hasError('pattern')
    ) {
      return 'Enter Proper Email Address';
    }
    if (
      controlName == 'mobile' &&
      this.contactUsFormGrp.controls[controlName].hasError('required')
    ) {
      return 'Enter mobile no';
    } else if (
      controlName == 'mobile' &&
      this.contactUsFormGrp.controls[controlName].hasError('pattern')
    ) {
      return 'Enter Numbers Only';
    }
    if (
      controlName == 'subject' &&
      this.contactUsFormGrp.controls[controlName].hasError('required')
    ) {
      return 'Enter the subject';
    } else if (
      controlName == 'subject' &&
      this.contactUsFormGrp.controls[controlName].hasError('pattern')
    ) {
      return 'Enter A Valid subject';
    }
    if (
      controlName == 'message' &&
      this.contactUsFormGrp.controls[controlName].hasError('required')
    ) {
      return 'Enter the message';
    } else if (
      controlName == 'message' &&
      this.contactUsFormGrp.controls[controlName].hasError('pattern')
    ) {
      return 'Enter A Valid Message';
    }
  }
}
