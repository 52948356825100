<section class="topimg about-header">
    <div class="container">
        <div class="row align-items-center d-flex">
            <div class="col-xl-6 col-lg-6 col-sm-12 col-12 d-flex align-items-center justify-content-center" data-aos="fade-up" data-aos-duration="2000">
                <div>
                    <h3 class="title-text">
                        Grow your Business & Customer Satisfaction with ComUnus
                    </h3>
                    <p class="title-text1">
                        ComUnus word comes from community, which joins two Latin word Com ('together’) and Unus ('one'). A community then is both one any many.
                    </p>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-sm-12 col-12 d-flex align-items-center justify-content-center" data-aos="fade-up" data-aos-duration="2000">
                <div class="header-img"></div>
            </div>
        </div>
    </div>
</section>
<!-- COUNTER DIV -->
<section id="counter" class="p-0" data-aos="fade-right" data-aos-duration="5000">
    <div class="container">
        <div class="col-md-12">
            <mat-card class="counter-card">
                <h2 class="text-center about-us-title">Our Achievements</h2>
                <mat-card-subtitle class="text-center">Comunus Where Client and Team matter the most</mat-card-subtitle>

                    <ul class="counter-list">
                        <div class="col-xs-6 inline-grid">
                    <li class="counter-item list-group-item text-center">
                        <h1 class="counter">{{happyClient}}+</h1>
                        <mat-card-subtitle>Happy Client</mat-card-subtitle>
                    </li>
                    <li class="counter-item list-group-item text-center">
                        <h1 class="counter">{{projectHours}} Lac+</h1>
                        <mat-card-subtitle>Project Hours</mat-card-subtitle>
                    </li>
                </div>
                    <div class="col-xs-6 inline-grid">
                    <li class="counter-item list-group-item text-center">
                        <h1 class="counter">{{teamStrength}}+</h1>
                        <mat-card-subtitle>Team Strength</mat-card-subtitle>
                    </li>
                    <li class="counter-item list-group-item text-center">
                        <h1 class="counter">{{tech}}+</h1>
                        <mat-card-subtitle>Technology & Skills</mat-card-subtitle>
                    </li>
                </div>
                </ul>

            </mat-card>
        </div>

    </div>
</section>

<!-- ABOUT US SECTION -->
<section  class="p-3">
    <div class="container about-us-container1">
        <div class="row pl-3 pr-3">
            <div class="col-6 col-md-6 col-sm-12 col-xs-12">
                <div class="about-us-img-cards">
                    <div class="row">
                        <div class="col-md-6 col-6 about-us-img-card-single">
                            <div class="about-us-img-div-first" [@valueChanged]="activeGalleryImages[0]" [ngStyle]="{
                  background:
                    'url(' + activeGalleryImages[0] + ') no-repeat 0 0 / cover'
                }"></div>
                        </div>
                        <div class="col-md-6 col-6 about-us-img-card-single">
                            <div class="about-us-img-div-second" [@valueChanged]="activeGalleryImages[1]" [ngStyle]="{
                  background:
                    'url(' + activeGalleryImages[1] + ') no-repeat 0 0 / cover'
                }"></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-6 about-us-img-card-single">
                            <div class="about-us-img-div-third" [@valueChanged]="activeGalleryImages[2]" [ngStyle]="{
                  background:
                    'url(' + activeGalleryImages[2] + ') no-repeat 0 0 / cover'
                }"></div>
                        </div>
                        <div class="col-md-6 col-6 about-us-img-card-single">
                            <div class="about-us-img-div-fourth" [@valueChanged]="activeGalleryImages[3]" [ngStyle]="{
                  background:
                    'url(' + activeGalleryImages[3] + ') no-repeat 0 0 / cover'
                }"></div>
                        </div>
                    </div>
                    <div class="about-us-circle">
                        <div class="about-us-inner-circle d-flex align-items-center justify-content-center text-center">
                            <div>
                                <h1 class="about-us-years-num">{{ years }}</h1>
                                <p class="about-us-years-text">
                                  Years
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-xs-12 col-md-6 d-flex align-items-center pl-2 pr-2 ">
                <div>
                    <h2 class="about-us-title">About Us</h2>
                    <p class="about-us-text">
                        Since 2016, ComUnus Technologies Pvt Ltd is one of the fastest growing IT Services and Consulting company based out of INDIA.
                    </p>
                    <p class="about-us-com-desc">
                        ComUnus word comes from community, which joins two Latin word
                        <span class="common-blue">Com</span>('together', 'with') and
                        <span class="common-orange">Unus</span>('One'). As the name suggest, we at ComUnus believe in building relationship with our customers, team members, investors, and partners.
                    </p>

                    <p class="about-us-text mt-3">
                        Technologies keep evolving, but relationship will always remain. Trust of our customers strengthen us to work so passionately to build solutions, that can bring our customers and their customers…come closer.
                    </p>
                    <p class="about-us-text mt-3">
                        We are delivering a whole lot for what lies upstream of innovation. Your Imaginations, Our Solutions…
                    </p>
                </div>
            </div>
            <div class="col-md-1 col-1"></div>
        </div>
    </div>
</section>

<!-- OUR COMP DIV -->
<section class="our-comp-container mt-5">
    <div class="container pt-3 pb-5">
        <div class="our-comp-header text-center mb-4">
            <h2 class="common-blue">Our Company Value</h2>
            <!-- <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sunt ducimus tempora blanditiis. <br /> Eaque provident, cupiditate excepturi in vero sapiente -->
            <!-- </p> -->
        </div>
        <div class="offset-md-1 col-md-11">
            <div class="row pl-3 pr-3 pb-5">
                <div class="col-md-4 col-sm-12  d-flex align-items-center justify-content-center">
                    <!-- <div class="dot-grey-bg">
                        <img src="assets/img/dot-grey-bg.png " />
                     </div> -->
                    <div class="our-comp-img position-relative mt-3">
                        <img src="assets/img/comittment-img.png" class="img-fluid" />
                    </div>
                     <!-- <div class="dot-org-bg">
                        <img src="assets/img/dot-org-bg.png" />
                     </div> -->
                </div>
                <div class="col-md-8 col-sm-12 col-12 p-0 mt-3">
                    <div class="our-comp-img-content d-flex ml-4">
                        <div class="col-md-6  col-12 p-2 d-flex align-items-center justify-content-center">
                            <div class="our-comp-img-content-card mt-3 ">
                                <div>
                                    <div class="common-orange">
                                        <img src="assets/img/social-care.png" class="mx-auto d-block
                                        " />
                                    </div>
                                    <h5 class="common-blue m-0">Transparency</h5>
                                    <p>
                                        Openness with our team and clients. We raise issues for the enhancement of client business and provide solutions promptly
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-12 p-2">
                            <div class="our-comp-img-content-card ">
                                <div>
                                    <div class="common-orange">
                                        <img src="assets/img/Commitment.png" class="mx-auto" />
                                    </div>
                                    <h5 class="common-blue m-0">Commitment</h5>
                                    <p>
                                        Our word and hard work over everything else. Our client and vision are our guiding beacons.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="our-comp-img-content d-flex ml-4">
                        <div class="col-md-6  col-12 p-2 d-flex align-items-center justify-content-center">
                            <div class="our-comp-img-content-card mt-3 ">
                                <div>
                                    <div class="common-orange">
                                        <img src="assets/img/Self-Improvement.png" class="mx-auto" />
                                    </div>
                                    <h5 class="common-blue m-0">Self Improvement</h5>
                                    <p>
                                        Never Stop learning, never stop evolving into who we want to be where we want to be.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6  col-12 p-2">
                            <div class="our-comp-img-content-card">
                                <div>
                                    <div class="common-orange">
                                        <img src="assets/img/human.png"  class="mx-auto"/>
                                    </div>
                                    <h5 class="common-blue m-0">Our Delivery</h5>
                                    <p>
                                        Deliver more value than our clients expect. Every day, over every delivery.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-values></app-values>
