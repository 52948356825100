<section class="topimg about-header">
    <div class="container">
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-sm-12 col-12 d-flex align-items-center justify-content-center"
                data-aos="fade-up" data-aos-duration="2000">
                <div>
                    <h3 class="title-text">
                        Banking & Finance
                    </h3>
                    <p class="title-text1">
                        The growing demands of technology have necessitated banks to apportion a large portion of their
                        budgets to IT.
                    </p>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-sm-12 col-12 d-flex align-items-center justify-content-center"
                data-aos="fade-up" data-aos-duration="2000">
                <div class="header-img"></div>
            </div>
        </div>
    </div>
</section>

<section class="custom-app">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <img src="assets/img/banking1.png" class="img-fluid" />
            </div>
            <div class="col-md-6">
                <div class="title-text1">
                    <p>
                        The growing demands of technology have necessitated banks to apportion a large portion of their
                        budgets to IT. To stay abreast of the technological developments and to retain clients,
                        flexibility through customized products and services has become imperative. Services like Core
                        Banking, Internet Banking, Mobile Banking, Electronic Funds Transfer, Customer Relationship
                        Management, Lease and Finance Management, Loan Origination system and Wealth Management, have
                        made IT an integral part of a Bank's operations. Moreover, compliance with government
                        regulations has made generation of specific reports mandatory. IT has helped Banks improve their
                        efficiency and enabled faster turnaround time. Since inception, ComUnus has been catering to
                        some of the largest Indian Banks.
                    </p>
                    <p>
                        It is often said that the chain is as strong as its weakest link. Unlike the monolithic
                        solutions of the past, today's solutions consist of multiple tiers requiring support of physical
                        servers, operating systems, virtual machines, application servers, web servers and databases.
                        Add to that the affinity of packaged applications to certain platforms and it is almost a
                        certainty that most enterprises will have several variations of these infrastructure components
                        in their data centers. Scalability of your application is subject to the scalability of these
                        components and hence it is not just important to maintain them but also to ensure that they
                        provide optimum performance. However, doing this entails having expensive time and resources
                        dedicated simply for ensuring uptime and throughput of your applications.
                    </p>
                </div>
            </div>

            <div class="dig-grid">
                <div class="col-md-6">
                    <div class="title-text1">
                        <p>
                            We, at ComUnus Technologies Pvt. Ltd., recognize this challenge and hence offer a
                            comprehensive range of services that cater to your infrastructure maintenance needs. We
                            ensure that your software infrastructure is not only monitored but also updated and made to
                            perform optimally at all times. Our SLA driven engagement models help you offload to us, the
                            responsibility of providing your business with maximum uptime and throughput.
                        </p>
                        <p>
                            We are proud to state that some of the largest enterprises with the most complex application
                            landscapes have chosen us as their partner for software infrastructure maintenance.
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <img src="assets/img/banking2.png" class="img-fluid" />
                </div>
            </div>
        </div>
    </div>
</section>

<!-- OUR INDUSTRY SECTION -->
<app-our-services></app-our-services>
