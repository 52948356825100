import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  animations: [
    trigger('valueChanged', [
      transition('void => *', []), // when the item is created
      transition('* => void', [
        style({
          opacity: 1,
        }),
        animate(
          '1s ease-out',
          style({
            opacity: 0,
          })
        ),
      ]), // when the item is removed
      transition('* => *', [
        // when the item is changed
        style({
          opacity: 0,
        }),
        animate(
          '1s ease-in',
          style({
            opacity: 1,
          })
        ),
      ]),
    ]),
  ],
})
export class AboutComponent implements OnInit {
  happyClient:number = 0;
  projectHours:number = 0;
  teamStrength:number = 0;
  tech:number = 0;

  happyClientStop:any = setInterval(()=>{
    this.happyClient++;
    if(this.happyClient==30)
    {
      clearInterval(this.happyClientStop);
    }
  },100)

  projectHourStop:any = setInterval(()=>{
    this.projectHours++;
    if(this.projectHours==3)
    {
      clearInterval(this.projectHourStop);
    }
  },1000)

  teamStrengthStop:any = setInterval(()=>{
    this.teamStrength++;
    if(this.teamStrength==175)
    {
      clearInterval(this.teamStrengthStop);
    }
  },50)

  techStop:any = setInterval(()=>{
    this.tech++;
    if(this.tech==30)
    {
      clearInterval(this.techStop);
    }
  },100)

  years = 8;
  galleryImages = [
    'assets/img/com-img15.jpg',
    'assets/img/com-img1.jpg',
    // 'assets/img/com-img2.jpg',
    'assets/img/com-img3.jpg',
    'assets/img/com-img4.jpg',
    'assets/img/com-img5.jpg',
    'assets/img/com-img6.jpg',
    'assets/img/com-img7.jpg',
    'assets/img/com-img9.jpg',
    'assets/img/com-img8.jpg',
    'assets/img/com-img9.jpg',
    'assets/img/com-img10.jpg',
    'assets/img/com-img11.jpg',
    'assets/img/com-img12.jpg',
    'assets/img/com-img13.jpg',
    'assets/img/com-img14.jpg',
    'assets/img/com-img16.jpg',
  ];
  activeGalleryImages = [];
  sliderInterval = 5;
  constructor() {}

  ngOnInit(): void {
    this.activeGalleryImages = this.galleryImages.slice(0, 4);
    this.startGallerySlider();
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
    },
    nav: false,
  };

  startGallerySlider() {
    setInterval(() => {
      this.activeGalleryImages = this.galleryImages
        .sort(() => Math.random() - Math.random())
        .slice(0, 4);
    }, this.sliderInterval * 1000);
  }
  getAboutUsImg(idx) {
    return this.activeGalleryImages[idx];
  }
}
