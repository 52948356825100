<main id="main">
    <section class="topimg about-header">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-lg-7 col-sm-12 col-12 d-flex align-items-center justify-content-center" data-aos="fade-up" data-aos-duration="2000">
                    <div>
                        <h3 class="title-text">Mobility Solutions</h3>
                        <p class="title-text1">
                            Mobile technologies today have untethered businesses from their locations, unlocked immense business value, and unleashed innovative solutions to help businesses grow.
                        </p>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-5 col-sm-12 col-12 d-flex align-items-center justify-content-center" data-aos="fade-up" data-aos-duration="2000">
                    <div class="header-img"></div>
                </div>
            </div>
        </div>
    </section>
    <section class="custom-app">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <img src="assets/img/mobility-solution.png" class="img-fluid"/>
                </div>
                <div class="col-md-6">
                    <div class="title-text1">
                        <p>
                            Mobility is considered as the most disruptive technology innovation in today's business world. In the past few years, mobile devices have grown from just enabling business emails and data on the move, to introducing capable applications and services that
                            change the way companies do business. Mobility helps companies leverage contextual data to reach out to customers effectively.
                        </p>
                        <p>
                            Mobile technologies today have untethered businesses from their locations, unlocked immense business value, and unleashed innovative solutions to help businesses grow. Enterprises are at the apex of realizing the immense potential of mobility.
                        </p>
                        <p>
                            ComUnus Technologies Pvt. Ltd. provides a wide range of mobility solutions that can give your business the true Mobile advantage. Our solution portfolio ranges from advisory services to development, testing and managed services that take end to end care
                            of mobility needs of a modernized business.
                        </p>
                        <p>
                            ComUnus provide full-fledged mobility solution from Native Android APP, Native iOS APP, J2ME Application, Hybrid APP, Micro Applications and APP Re-engineering with maintenance.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- OUR INDUSTRY SECTION -->
    <app-our-services></app-our-services>
</main>
